import React, { useContext } from "react";
import { StaffContext } from "../../../../../Context";
import useDBO from "../../../hooks/useDBO";
import Staff from "../../../components/Staff";
import { startOfWeek } from "date-fns";
import { ht } from "date-fns/locale";



export default function PrintTimetable({ classes, startTimes, locations }) {

  let tests = useDBO(`/tests-by-date/${new Date().tDate()}`);  
  if (tests.loading) return <></>
    return (
      <div>
        {startTimes.map((st) => (
          <ClassesAtTime
            time={st}
            classes={classes.filter((c) => c.startTime.substring(0, 5) === st)}      
            tests={tests}
          />
        ))}
      </div>
    );
}

function ClassesAtTime({ time, classes,tests}) {
    return <div>
        <table className="table-sm" style={{ width: "100%" }}>
            <tr>
         <th colSpan={5} style={{textAlign:"center", background:"#888", color: "white"}}>
               {time}
                </th>
            </tr>
            <tr>
                <th style={{width: "15%"}}>Name</th>
                <th  style={{width: "15%"}}>Teacher</th>
                <th  style={{width: "15%"}}>Room</th>
                <th style={{ width: "15%" }}>Extra teachers</th>
                 <th  style={{width: "15%"}}>Notes</th>
            </tr>
            {classes.map(c => <DrawClass htClass={c} tests={tests} />)}    
        </table>
        </div>
}
function DrawClass({ htClass, tests }) {
    const test = tests.find((t) => t.htClass === htClass._id);
    let notes = ""
    if (test) notes = test.room
    if (htClass.adminNotes) {
        notes += "\n" + htClass.adminNotes
    }
      return (
        <tr>
          <td style={{ width: "15%" }}>{htClass.name}</td>
          <td style={{ width: "15%" }}>{<Staff id={htClass.teacher} />}</td>
          <td style={{ width: "15%" }}>{htClass.room}</td>
          <td style={{ width: "15%" }}>
            <GetFT             
              htClass={htClass}
            />
          </td>
          <td style={{ width: "40%" }}>
            {notes}
          </td>
        </tr>
      );
}

function GetFT({  htClass })
{
  if (!htClass ) {
    return <>Error</>
  }
   if(!htClass.foreignTeacher) return <></>
   
    return (
      <>
        <div style={{display:"flex", justifyContent: "space-between", marginRight:20}}>
          <Staff id={htClass.foreignTeacher} />{" "}
          <div>
            {htClass.FTStartTime} - {htClass.FTEndTime}
          </div>
        </div>
      </>
    );
}
