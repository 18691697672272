import React from "react";
import "./classview.css";
import { Box, LinearProgress } from "@mui/material";
import Lang from "../../../components/Lang";
import Staff from "../../../components/Staff";

export default function ClassView(props) {
  let { htClass, setSelected } = props;

  const progress = (100 / htClass.students.length) * (htClass.attended.length + htClass.late.length + htClass.absent.length + htClass.noShow.length)
  let color = "error"
  if (progress > 50) color = "warning"
  if (progress>99) color= "success"


  return (
    <div
      onClick={() => setSelected(htClass._id)}
      className={"attendance-frame"}
    >
   
      <div style={{padding:5, paddingLeft:10}}>
          <div style={{fontSize:14, color:"white"}}>{htClass.name}</div>
          <div style={{fontSize:12, color:"yellow"}}>
            <Staff id={htClass.teacher} />
          </div>
          <div style={{fontSize:12, color: "lightblue"}}>{htClass.room}</div>

     
        
  
      </div>
      <LinearProgress
        value={progress}
        variant="determinate"
        color={color}
        sx={{ ml: 1, mr: 1, mt: 0.5, mb: 0.5 }}
        style={{outline:"red"}}
   
      />
      <div className="attendance-students-frame">
        <div
          style={{
            color: "#FFFFFF",
            backdropFilter: "blur(4px)",
          }}
        >
          <Lang w="students" />: {htClass.students.length}
          <div style={{ marginTop: 7 }}></div>
        </div>
        <div>
          <DrawAttendanceHtClass htClass={htClass} />
        </div>
      </div>
   
    </div>
  );
}


function DrawAttendanceHtClass(props) {
  let { htClass } = props;
/*   let attended =
    htClass.lesson && htClass.lesson.attended
      ? htClass.lesson.attended.length
      : 0;
  let late =
    htClass.lesson && htClass.lesson.late ? htClass.lesson.late.length : 0;
  let absent =
    htClass.lesson && htClass.lesson.absent ? htClass.lesson.absent.length : 0;
  let noShow =
    htClass.lesson && htClass.lesson.noShow
      ? htClass.lesson.noShow.length
      : "0"; */

  return (
    <div>
      {" "}
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "success.light" }}
      >
        <div style={{ position: "relative", left: 40 }}>Attended:</div>{" "}
        <div
          style={{
            position: "relative",
            left: 35,
            textAlign: "right",
            width: 20,
          }}
        >
          {htClass.attended.length}
        </div>
      </Box>
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "yellow" }}
      >
        <div style={{ position: "relative", left: 40 }}>Late:</div>{" "}
        <div
          style={{
            position: "relative",
            left: 61,
            textAlign: "right",
            width: 20,
          }}
        >
          {htClass.late.length}
        </div>
      </Box>
      <Box
        style={{ display: "flex", lineHeight: 1.2 }}
        sx={{ color: "warning.light" }}
      >
        <div style={{ position: "relative", left: 40 }}>Absent:</div>
        <div
          style={{
            position: "relative",
            left: 46,
            textAlign: "right",
            width: 20,
          }}
        >
          {htClass.absent.length}
        </div>
      </Box>
      <Box style={{ display: "flex", lineHeight: 1.2, color: "#fa8989" }}>
        <div style={{ position: "relative", left: 40, width: 60 }}>
          No Show:
        </div>
        <div
          style={{
            position: "relative",
            left: 28,
            textAlign: "right",
            width: 20,
          }}
        >
          {htClass.noShow.length}
        </div>
      </Box>
    </div>
  );
}
