import {Button,Dialog,DialogActions,DialogContent,DialogTitle, TextField} from "@mui/material";
import React, { useEffect, useState } from "react";
import LocationChoice from "../../../components/LocationChoice";
import RoomChoice from "../../../components/RoomChoice";
import mut from "../../../hooks/mut";
import RadioGroupHT from "../../../components/RadioGroupHT";
import TimeClock from "../../../components/TimeClock";
import DayOfWeekChoice from "../../../components/DayOfWeekChoice";
import StaffChoice from "../../../components/StaffChoice";
import Lang from "../../../components/Lang";
import replaceById from "../../../hooks/replaceById";

export default function EditClass(props) {
    const { open, setOpen, htClass, setClasses,week } = props;
    const [htc, setHtc] = useState(htClass)
    const [future,setFuture] = useState("allFutureClasses")

    const update = async () => { 
      let result = await mut("post", `/class-timetable/${htc._id}${future === "allFutureClasses" ? "/future" : ""}`, htc)
      console.log(result)
        replaceById(setClasses,result)
        setOpen(false)
    }
  
  useEffect(() => {
    setHtc(htClass)
  },[htClass])
  
  const width=470

  return (
    <Dialog
      open={open}

      maxWidth="xl"
    >
      <DialogTitle style={{display:"flex", justifyContent: "space-between"}}>
        <div>Edit {htc.name}</div>
        <RadioGroupHT
          choices={["allFutureClasses", "thisClass"]}
          value={future}
          setValue={setFuture}
          label={"changeFor"}
        />
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          paddingTop: 5,
        }}
      >
        <div style={{ display: "flex", gap: 20 }}>
          <div style={{ display: "flex", flexDirection: "column", gap: 20, width }}>
         
            <DayOfWeekChoice
              value={htc.dayOfWeek || 0}
              setValue={(day) =>
                setHtc((prev) => ({ ...prev, dayOfWeek: day.target.value }))
              }
              label={<Lang w="dayOfTheWeek" />}
            />
            <LocationChoice
              value={htc.location}
              setValue={(location) => setHtc((prev) => ({ ...prev, location }))}
              fullWidth
              size="normal"
            />
            <RoomChoice
              location={htc.location}
              value={htc.room}
              setValue={(room) => {
                setHtc((prev) => ({ ...prev, room }));
              }}
              fullWidth
            />
            <div style={{ display: "flex", gap: 10 }}>
              <TextField
                value={htc.unitNumber}
                label={<Lang w="unit" />}
                type="number"
                onChange={(e) =>
                  setHtc((prev) => ({ ...prev, currentUnit: e.target.value }))
                }
                fullWidth
              />
              <TextField
                value={htc.lessonNumber}
                label={<Lang w="lesson" />}
                type="number"
                onChange={(e) =>
                  setHtc((prev) => ({ ...prev, currentLesson: e.target.value }))
                }
                fullWidth
              />
            </div>
          </div>
          <div style={{width}}>
     
              <StaffChoice
                value={htc.teacher}
                setValue={(teacher) => setHtc((prev) => ({ ...prev, teacher }))}
                filter="Teacher"
                label="teacherOrTa"
                fullWidth
              />{" "}
        

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <TimeClock
                value={htc.startTime}
                setValue={(startTime) =>
                  setHtc((prev) => ({ ...prev, startTime }))
                }
                label="startTime"
              />
              <TimeClock
                value={htc.endTime}
                setValue={(endTime) => setHtc((prev) => ({ ...prev, endTime }))}
                label="finishingTime"
              />
            </div>
          </div>
          {/* <div style={{width}}>
            <StaffChoice
              label="foreignTeacher"
              filter="Foreign Teacher"
              none
              value={htc.foreignTeacher}
              setValue={(foreignTeacher) =>
                setHtc((prev) => ({ ...prev, foreignTeacher }))
              }
              fullWidth
              style={{width}}
            />
            {htc.foreignTeacher && (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <TimeClock
                  value={htc.FTStartTime}
                  setValue={(FTStartTime) =>
                    setHtc((prev) => ({ ...prev, FTStartTime }))
                  }
                  label="startTime"
                />
                <TimeClock
                  value={htc.FTEndTime}
                  setValue={(FTEndTime) =>
                    setHtc((prev) => ({ ...prev, FTEndTime }))
                  }
                  label="finishingTime"
                />
              </div>
            )}
          </div> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
          color="success"
          onClick={update}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}