import { useEffect, useState } from "react";
import { apiKey } from "../../../App";
import axios from "axios";

/**
 * @typedef {object} Usedbo
 * @property {array} get - The array of data
 * @property {function} set - A function to update the state array
 * @property {boolean} loading - A boolean indicating if the data is being loaded
 * @property {string} error - An error message if any
 * @property {function} add - A function to add an item to the array
 * @property {function} remove - A function to remove an item from the array
 * @property {function} update - A function to update an item in the array
 * @property {function} updateById - A function to update an item in the array
 * @property {function} filter - A function to filter the array
 * @property {function} log - A function to log the array to the console
 * @property {function} sort - A function to sort the array
 * @property {function} sortBy - A function to sort the array by a field
 * @property {function} refresh - A function to refresh the data
 * @property {function} reduce - A function to reduce the array
 * @property {function} uniqueSet - A function to get a unique set of values from a field
 * @property {function} map - A function to map the array
 * @property {function} find - A function to find an item in the array
 */

/**
 * A hook to get an array of data from the database and perform CRUD operations
 * @param {string} url - The URL to fetch data from
 * @param {number} [reLoad=0] - The interval in seconds to reload the data
 * @param {array} dependencies optional, array of dependencies to watch and reload if they change
 * @returns {Usedbo} An object containing the array of data, functions to update the array, and other properties
 */
export default function useDBO(url, reLoad = 0, dependencies=[]) {
  const [get, set] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(url);

    if (reLoad < 1) return;

    const interval = setInterval(() => {
      getData(url, false);
    }, reLoad * 1000);

    return () => clearInterval(interval);
  }, [url,...dependencies]);

  async function getData(url, showLoading = true) {
    try {
      if (showLoading) setLoading(true);
      const response = await axios({
        method: "get",
        url: `/api${url}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey,
        },
      });
      set(response.data);
    } catch (err) {
      setError(err);
    } finally {
      if (showLoading) setLoading(false);
    }
  }

  /**
   * A function to add an item to the array
   * @param {object} item the item to add
   */
  function add(item) {
    set([...get, item]);
  }
  
  /**
   * Logs the value of the `get` variable to the console.
   */
  function log() {
    console.log(get);
  }
  
  /**
   * Refreshes the data by calling the getData function with the specified URL.
   * 
   * @async
   * @function
   * @returns {Promise<void>} A promise that resolves when the data has been refreshed.
   */
  async function refresh() {
    await getData(url, false);
  }

  /**
   * A function to update an item in the array.
   * @param {object} item the new data to update in the item, it will match by _id
   */
  function update(item) {
    set(get.map((i) => (i._id === item._id ? { ...i, ...item } : i)));
  }

  /**
   * A function to update an item in the array by its _id
   * @param {string} id the _id of the item to update
   * @param {object} item the new item to update
   */
  function updateById(id, item) {
    set(get.map((i) => (i._id === id ? { ...i, ...item } : i)));
  }

  function updateId(item) {
    set(get.map((i) => (i.id === item.id ? { ...i, ...item } : i)));
  }

  /**
   * A function to remove an item from the array
   * @param {string|object} id the _id of the item to remove, can be an object or a string
   */
  function remove(id) {
    if (typeof id === "object") {
      set(get.filter((item) => item._id !== id._id));
      return;
    }
    set(get.filter((item) => item._id !== id));
  }

  /**
   * A function to filter the array
   * @param {function} filter a function that takes an item and returns true if it should be included in the filtered array
   */
  function filter(filter) {
    return get.filter(filter);
  }
  
  /**
   * Sorts the data based on the provided sort criteria.
   *
   * @param {Object} sort - The sort criteria to apply.
   * @returns {Array} The sorted data.
   */
  function sort(sort) {
    return get.sort(sort);
  }

  /**
   * Sorts an array of objects by a specified field in either ascending or descending order.
   *
   * @param {string} field - The field name to sort by.
   * @param {number} [direction=1] - The direction of the sort. Use 1 for ascending and -1 for descending.
   * @returns {Array} - The sorted array.
   */
  function sortBy(field, direction = 1) {
    return get.sort((a, b) =>
      a[field] > b[field] ? direction : b[field] > a[field] ? 0 - direction : 0
    );
  }

  /**
   * Reduces an array of objects by summing up the values of a specified field.
   *
   * @param {string} field - The field name whose values will be summed.
   * @returns {number} The sum of the values of the specified field.
   */
  function reduce(field) {
    return get.reduce((acc, item) => acc + item[field], 0);
  }

  /**
   * Returns an array of unique values for a specified field from an array of objects.
   *
   * @param {string} field - The field name to extract unique values from.
   * @returns {Array} An array of unique values for the specified field.
   */
  function uniqueSet(field) {
    return [...new Set(get.map((a) => a[field]))];
  }

  /**
   * Applies a given function to each element in the collection and returns a new collection with the results.
   *
   * @param {Function} fn - The function to apply to each element.
   * @returns {Array} A new collection with the results of applying the function to each element.
   */
  function map(fn) {
    return get.map(fn);
  }

  /**
   * Finds an item in the database that matches the given filter.
   *
   * @param {Object} filter - The filter criteria to match items against.
   * @returns {Object|undefined} The found item, or undefined if no item matches the filter.
   */
  function find(filter) {
    if (get) return get.find(filter);
    return undefined;
  }

  return {
    get,
    set,
    error,
    loading,
    add,
    remove,
    update,
    updateById,
    updateId,
    filter,
    log,
    sort,
    sortBy,
    refresh,
    reduce,
    uniqueSet,
    map,
    find,
  };
}
