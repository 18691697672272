import React, { useContext } from "react";
import { MenuItem, TextField } from "@mui/material";
import { StaffContext } from "../../../Context";
import Lang from "./Lang";

export default function TeacherChoiceObject({object,setObject,fullWidth,field="teacher"} ) {
  const { getStaffRole } = useContext(StaffContext)
  console.log(object)
  return (
    <div>
      <TextField
        select
        label={<Lang w="teacher" />}
        value={object[field]}
        onChange={(e) => setObject(prev=>({...prev,[field]:e.target.value}))}
        fullWidth={fullWidth}
      >
        {getStaffRole("Teacher")
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .map((staffm) => (
            <MenuItem
              key={staffm._id}
              value={staffm._id}
            >
              {staffm.name}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
}
