import React from "react";
import AddButton from "./AddButton";
import {Button,Dialog,DialogActions,DialogContent,DialogTitle, IconButton} from "@mui/material";
import useDBO from "../hooks/useDBO";
import Lang from "./Lang";
import Loading from "../Loading";
import mut from "../hooks/mut";
import "./bookList.css"

import IconButtonHT from "./IconButtonHT";
import Search from "./Search";


export default function BookList({syllabus,setSyllabus}) {
    const [open, setOpen] = React.useState(false);

    return (
      <div>
        <div style={{ border: "1px solid #ccc", borderRadius: 5, padding: 5 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#ccc",
              borderRadius: 5,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div><Lang w="books"/></div>
            <div>
              <AddButton action={() => setOpen(true)} />
            </div>
          </div>
          <div style={{padding: 10}}>
            {syllabus.books.map((book) => (
              <DrawBook
                book={book}
                    key={book._id}
                    syllabus={syllabus}
                    setSyllabus={setSyllabus}
              />
            ))}
          </div>
        </div>
        <BookChoice
          open={open}
          setOpen={setOpen}
          syllabus={syllabus}
          setSyllabus={setSyllabus}
        />
      </div>
    );
}

function DrawBook({book, syllabus, setSyllabus}) {
    return (
      <div style={{display:"flex", justifyContent: "space-between"}}>
            <div>{book.name}</div>
            <div><IconButtonHT icon="cancel" style={{ color: "red" }} action={() => {
                setSyllabus({ ...syllabus, books: syllabus.books.filter(b => b._id !== book._id) })
                mut("post", `/update-syllabus/${syllabus._id}`, {books: syllabus.books.filter(b => b._id !== book._id).map(b => b._id)})
            }} /></div >
      </div>
    );
}





function BookChoice(props) {
  const { open, setOpen, syllabus, setSyllabus } = props;
  const bookIds = syllabus.books.map(b => b._id)
    const books = useDBO(`/books`)
    const [search,setSearch] = React.useState("")
    if(books.loading) return <Loading />

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>
        <Lang w="addANewBook" />
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: 10 }}>
          <Search
            search={search}
            setSearch={setSearch}
            fullWidth
          />
        </div>

        {books
          .sortBy("name")
          .filter(
            (b) =>
              !bookIds.includes(b._id) &&
              b.name.toUpperCase().includes(search.toUpperCase())
          )
          .map((b) => (
            <div
              className="book-in-list"
              onClick={() => {
                setSyllabus({ ...syllabus, books: [...syllabus.books, b] });
                setOpen(false);
                const bks = syllabus.books.map((b) => b._id);
                bks.push(b._id);
                mut("post", `/update-syllabus/${syllabus._id}`, { books: bks });
              }}
            >
              {b.name}
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}