import React, { useState } from "react";
import useData from "../../hooks/useData";  
import useDBO from "../../hooks/useDBO";
import Staff from "../../components/Staff";
import Info from "../../components/Info";
import DatePicker from "../../components/DatePicker";
import SLink from "../../components/SLink";
  
  export default function CareReport(props) {
  
    const [tDate,setTDate] = useState(new Date())

    const staff = useDBO("/call-log/" + tDate.tDate())
    if (staff.loading) return <div>Loading...</div>
    if (staff.error) return <div>Error: {staff.error}</div>

    if(staff.length===0) return <div>Nothing recorded today</div>
   
    console.log(staff.get)
    return (
      <div>
        <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
          <div>Care report</div>
          <DatePicker
            value={tDate}
            setValue={setTDate}
          />
        </div>
        {staff.get.map((s) => (
          <div style={{ marginBottom: 10 }}>
            <h3>
              <Staff id={s._id} />
            </h3>
            <h4>Notes recorded ({s.notes.length})</h4>
            {s.notes.map((n) => (
              <div>
                <Info
                  title={new Date(n.createdAt).fTimeDate()}
                  body={
                    <div>
                      <div>
                        <SLink id={n.student} />
                      </div>
                      <div>{n.note}</div>
                    </div>
                  }
                />
              </div>
            ))}
            <h4>Messages recorded({s.messages.length})</h4>
            {s.messages.map((n) => (
              <div>
                <Info
                  title={new Date(n.createdAt).fTimeDate()}
                  body={
                    <div>
                      <div>
                        {n.studentsTagged.map(s => <SLink id={s} />)}
                      </div>
                      <div>{n.description}</div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
};


  