import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Loading";
import Error from "../../components/Error";
import useDB from "../../hooks/useDB";
import AddButton from "../../components/AddButton";
import Lang from "../../components/Lang";
import DialogHT from "../../components/DialogHT";
import useDBO from "../../hooks/useDBO";
import Text from "../../components/Text";
import { TextField } from "@mui/material";
import mut from "../../hooks/mut";


export default function Unit() {
    const { id,unitid } = useParams();
    const [unit, setunit, error, loading] = useDB(`/get-unit/${unitid}`)
    
    if (loading) return <Loading />
    if(error) return <Error />

    console.log(unit)
    return (
      <div>
        <Link to={`/teaching/syllabus/`}>Syllabus</Link> /{" "}
        <Link to={`/teaching/syllabus/${unit.syllabus._id}`}>
          {unit.syllabus.name}
        </Link>{" "}
        / Unit {unit.unitNumber} - {unit.name}
        <div style={{display:"flex", flexDirection:"column", gap:10, paddingTop:10}}>
          <Text
            value={unit.name}
            setValue={(name) => setunit({ ...unit, name })}
            url={`/update-unit/${unit._id}`}
            field="name"
            label={<Lang w="name" />}
          />
          <Lessons unit={unit} />
        </div>
      </div>
    );
}


function Lessons({ unit }) {
    
  /*    const [openNewLesson, setOpenNewLesson] = useState(false) */
  const [name, setName] = useState("")
    const lessons = useDBO(`/get-unit-lessons/${unit._id}`)

  if (lessons.loading) return <Loading />
  if (lessons.error) return <Error />
  
  const checkKey = async (e) => {
    if (e.keyCode === 13 && name.length>0) {
     let res = await mut("put", "/add-lesson/", {
        syllabus: unit.syllabus._id,
        unit: unit._id,
        name
     });
      lessons.add(res)
      setName("")
    }
  }

    return (
      <div>
        <div>
          {" "}
          <Lang w="lessons" />
        </div>
        <div style={{marginBottom:10}}>
          {lessons.get.length > 0 ? (
            lessons.get.map((lesson) => (
              <div>
                <Link
                  to={`/teaching/syllabus/${unit.syllabus._id}/unit/${unit._id}/lesson/${lesson._id}`}
                >
                  {" "}
                  Lesson {lesson.lessonNumber} - {lesson.name}
                </Link>
              </div>
            ))
          ) : (
            <div>No lessons</div>
          )}
        </div>
        <TextField
          value={name}
          onChange={(e) => setName((prev) => e.target.value)}
          label={<Lang w="addANewLesson" />}
          size="small"
          sx={{ width: 600 }}
          onKeyUp={checkKey}
        />
      </div>
    );
    
}

const content = [
  {
    name: "name",
    label: "name",
    type: "text",
  },
];