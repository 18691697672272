import React, { useContext, useState } from "react";
import Loading from "../../Loading";
import { Table } from "antd";
import { CenterContext, UserContext } from "../../../../Context";
import VND from "../../components/VND";
import FormatDate from "../../components/FormatDate";
import Staff from "../../components/Staff";
import Location from "../../components/Location";
import MonthChoice from "../../components/MonthChoice";
/* import { exportFile } from "table-xlsx"; */
import DeleteButton from "../../components/DeleteButton";
import SelectFromList from "../../components/SelectFromList";
import useDB from "../../hooks/useDB";
import Error from "../../components/Error";
import removeById from "../../hooks/removeById";
import FormatDateYMD from "../../components/FormatDateYMD";
import Lang from "../../components/Lang";
import CostTypeChooser from "../../components/CostTypeChooser";


export default function Costs(props) {

    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [costs, setCosts, error, loading] = useDB(
      `/costs?year=${year}&month=${month + 1}`
    );
    const { center } = useContext(CenterContext)
    const { user } = useContext(UserContext)
    const [filter, setFilter] = useState("")

  if (loading) return <Loading />
  if(error) return <Error />
  
    const cols = getCols(user)

/*     const onExportFileClick = () => {
      exportFile({
        columns: getCols(),
        dataSource: costs
          .filter((c) => (department ? c.department === department : true))
          .map((c) => ({
            ...c,
            date: c.date.substring(0, 10),
            department: center.departments.find((d) => d._id === c.department)
              ? center.departments.find((d) => d._id === c.department).name
              : "None",
            paymentMethod: c.paymentMethod === 0 ? "Cash" : "Internet",
            orderedBy: findStaff(c.orderedBy).name,
            location: center.locations.find((l) => l._id === c.location).name,
          })),
        fileName: `costs-${year}-${month + 1}.xlsx`,
      });
    }; */
  
    


    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: 24,
            marginBottom: 5,
          }}
        >
          <div><Lang w="costs" /></div>
          <CostTypeChooser state={filter} setState={setFilter} style={{ width: 200 }} size="small" />
          <MonthChoice
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
          />
        
          <div>
            <VND
              value={costs
                .filter((c) => (filter ? c.category === filter : true))
                .reduce((a, b) => a + b.amount, 0)}
            />
          </div>
        </div>
        <Table
          columns={cols}
          dataSource={costs
            .filter((c) => (filter ? c.category === filter : true))
            .map((c) => ({
              ...c,
              category: <Lang w={c.category} />,
              paymentMethod: c.paymentMethod === 0 ? "Cash" : "Internet",
              amount: <VND value={c.amount} />,
              date: <FormatDateYMD value={c} />,
              orderedBy: <Staff id={c.orderedBy} />,
              location: <Location id={c.location} />,
              deleteButton: user.roles.includes("Owner") ? (
                <DeleteButton
                  route={`/costs/${c._id}`}
                  success={() =>
                    removeById(setCosts,c)
                  }
                />
              ) : null,
            }))}
        />
      </div>
    );
}


function getCols(user) {
    const cols = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
    
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Person",
        dataIndex: "orderedBy",
        key: "orderedBy",
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
      },
      {
        title: "Method",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
    ];
    if (user && user.roles.includes("Owner")) {
        cols.push({
          title: "Delete",
          dataIndex: "deleteButton",
          key: "deleteButton",
        });
    }
    return cols
}
