import React from "react";
import {
  IconButton,
  Box,
  Stack,
  TextField,
  Chip,
  MenuItem,
  Rating,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import { net } from "../../../../../database";
import CenterChoices from "../../../components/CenterChoices";
import Lang from "../../../components/Lang";

export default function SalesInfo(props) {
  const { language, l, customer, setCustomer,updateCustomer } = props;
 /*  const [edit, setEdit] = React.useState(false); */

  const [customers, setCustomers] = React.useState([]);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    net.get("/customers").then((r) => setCustomers(r));
  }, []);

  const deleteFriend = (e, id, name) => {
    let friends = props.customer.friends.filter((f) => f._id !== id);
    props.customer.friends = friends;
    props.setCustomer(props.customer);
    props.updateCustomer();
  };
  const addFriend = (e, _id, name) => {
    props.customer.friends.push({ _id, name });
    props.setCustomer(props.customer);
    props.updateCustomer();
    setSearch("");
  };

  /* const toggleEdit = () => {
    if (edit) {
      setEdit(false);
      props.updateCustomer();
    } else {
      setEdit(true);
    }
  }; */

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      ></div>
      <CenterChoices value={customer.marketingSource}
        setValue={(marketingSource) => {
          setCustomer(prev => ({ ...prev, marketingSource }))
        }
        }
        centerField="marketingSources"
        label={<Lang w="marketingSource" />}
        url={`/customers/${customer._id}/update`}
        field="marketingSource"
        style={{marginBottom:5}}
        />
        
      <Box
        border={1}
        padding={1.2}
        borderRadius={2}
        marginBottom={1}
      >
        <div style={{ fontSize: 16, marginBottom: 10 }}>
          {l.learning[language]}
        </div>
        <Stack spacing={2}>
          <TextField
            value={props.customer.reasonForLearning}
            label={l.reasonForLearning[language]}
            onChange={(e) =>
              props.setCustomer({
                ...props.customer,
                reasonForLearning: e.target.value,
              })
            }
            onBlur={() => props.updateCustomer()}
            {...(props.customerErrors.includes("reasonForLearning") && {
              error: true,
              helperText: l.pleaseTypeInTheCustomersReasonForLearning[language],
            })}
          />
          <TextField
            value={props.customer.problemsWithChildren}
            label={l.problemsWithTheCustomersChildren[language]}
            onChange={(e) =>
              props.setCustomer({
                ...props.customer,
                problemsWithChildren: e.target.value,
              })
            }
            {...(props.customerErrors.includes("problemsWithChildren") && {
              error: true,
              helperText: l.pleaseDescribeAnyProblemsTheChildrenHave[language],
            })}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              {...(props.customerErrors.includes("englishKnowledge") && {
                style: { color: "red", textDecoration: "underline" },
              })}
            >
              {l.customersEnglishKnowledge[language]}
            </div>
            <Rating
              value={props.customer.englishKnowledge}
              onChange={(e) =>
                props.setCustomer({
                  ...props.customer,
                  englishKnowledge: e.target.value,
                })
              }
            />
          </div>
        </Stack>
      </Box>
      <Box
        border={1}
        padding={1.2}
        borderRadius={2}
        marginBottom={1}
      >
        <div style={{ fontSize: 16, marginBottom: 10 }}>
          {l.financial[language]}
        </div>
        <Stack spacing={2}>
          <TextField
            value={props.customer.paymentFrequency}
            fullWidth
            label={l.howOftenWouldTheyLikeToPay[language]}
            onChange={(e) =>
              props.setCustomer({
                ...props.customer,
                paymentFrequency: e.target.value,
              })
            }
            {...(props.customerErrors.includes("howTheyLikeToPay") && {
              error: true,
              helperText:
                l.pleaseChooseHowOftenTheCustomerWouldLikeToPay[language],
            })}
            select
          >
            <MenuItem value={1}>1 month</MenuItem>

            <MenuItem value={3}>3 months</MenuItem>

            <MenuItem value={6}>6 months</MenuItem>
          </TextField>
          <div style={{ display: "flex" }}>
            <TextField
              value={
                props.customer.jobs !== undefined ? props.customer.jobs[0] : ""
              }
              label={l.fathersJob[language]}
              sx={{ marginRight: 1 }}
              onChange={(e) => {
                let jobs = props.customer.jobs;
                jobs[0] = e.target.value;
                props.setCustomer({
                  ...props.customer,
                  jobs,
                });
              }}
              {...(props.customerErrors.includes("jobs0") && {
                error: true,
                helperText: "",
              })}
            />
            <TextField
              value={
                props.customer.jobs !== undefined ? props.customer.jobs[1] : ""
              }
              label={l.mothersJob[language]}
              onChange={(e) => {
                let jobs = props.customer.jobs;
                jobs[1] = e.target.value;
                props.setCustomer({
                  ...props.customer,
                  jobs,
                });
              }}
              {...(props.customerErrors.includes("jobs1") && {
                error: true,
                helperText: "",
              })}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ marginRight: 20 }}
              {...(props.customerErrors.includes("income") && {
                style: { color: "red", textDecoration: "underline" },
              })}
            >
              {l.householdIncome[language]}
            </div>
            <Rating
              value={props.customer.income}
              onChange={(e) =>
                props.setCustomer({
                  ...props.customer,
                  income: parseInt(e.target.value),
                })
              }
            />
          </div>
        </Stack>
      </Box>
      <Box
        border={1}
        padding={1.2}
        borderRadius={2}
      >
        <div style={{ fontSize: 11 }}>
          {l.friendsAtTheCenter[language]}
          <div style={{ display: "flex" }}>
            <TextField
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search !== "" ? (
              <div>
                {customers
                  .filter(
                    (c) =>
                      (c.name &&
                        c.name
                          .toLowerCase()
                          .includes(search.toLocaleLowerCase())) ||
                      (c.phone && c.phone.includes(search))
                  )
                  .slice(0, 10)
                  .map((c) => (
                    <div style={{ fontSize: 14, marginLeft: 10 }}>
                      {c.name ? c.name : c.phone}
                      <IconButton
                        onClick={(e) => addFriend(e, c._id, c.name, c.phone)}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {props.customer.friends.map((f) => (
              <Chip
                label={f.name ? f.name : f.phone}
                onDelete={(e) => deleteFriend(e, f._id, f.name)}
                sx={{ marginRight: 1 }}
              />
            ))}
          </div>
        </div>
      </Box>
    </div>
  );
}
