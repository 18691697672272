import React, { useContext } from "react";
import SelectFromList from "./SelectFromList";
import { CenterContext } from "../../../Context";
import Lang from "./Lang";
import { MenuItem, TextField } from "@mui/material";


export default function CostTypeChooser({state,setState, size, style, fullWidth, none}) {
const {center} = useContext(CenterContext)

    return ( <TextField
        size={size}
        label={<Lang w="category" />}
        style={style}
        value={state}
        select
        onChange={(e) => setState(e.target.value)}
      
    
      >
        {none ? (
          <MenuItem value="" key="none">
            None
          </MenuItem>
        ) : null}
        {center.costCategory.map((choice,i) => (
          <MenuItem value={choice} key={choice+i}>
            <Lang w={choice} />
          </MenuItem>
        ))}
      </TextField>
        
        
        
     
)}