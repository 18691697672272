
import { addDays, isToday } from "date-fns";
import React from "react";
import ChooseDate from "./ChooseDate";
import { IconButton } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";



/**
 * Description placeholder
 * @date 8/8/2023 - 8:58:32 PM
 *
 * @export
 * @param {date} value
 * @param {function} setValue optional
 * @param {boolean} buttons show buttons to move forward and backwards days
 * @param {string} align align the date in the picker
 * @returns {*}
 */
export default function HtDate(props) {
  let { value, setValue, buttons,style={},future,align,disabled=false } = props;
 
  if (!value) return (<React.Fragment>No date</React.Fragment>);
  let dateIn = value;
    if (typeof value === "string") dateIn = new Date(value);

    let showDate = "Invalid Date"
    try {
        showDate = dateIn.fDate();
    } catch (err) {
        //console.log(err)
    }
  if (!setValue) {
    return <>{showDate}</>;
  }
  return (
    <div style={{display:"flex", gap:5}}>
      {buttons ? <DrawButton direction="down" setValue={setValue} value={value} future={future} disabled={disabled} /> : null}
      <ChooseDate
        value={value}
        setValue={setValue}
        style={style}
        align={align}
        future={future}
      />
      {buttons ? <DrawButton direction="up" setValue={setValue} value={value} future={future} disabled={disabled}/> : null}
    </div>
  );
}

function DrawButton({ direction, setValue, value,future, disabled}) {

  const ad = () => {
    setValue(addDays(value,direction==="up" ? 1 : -1))
    console.log(value)
    /* setValue((prev) => {  
      console.log("here")
      let dt = addDays(prev, direction === "up" ? 1 : -1)
      console.log(dt)
      return dt
    }); */
  }

  let dis = disabled
  if(isToday(value) && direction==="up" && future===false) dis = true

  return (
    <IconButton
      onClick={ad}
      disabled={dis}
    >
      {direction === "down" ? (
        <ArrowCircleLeftIcon />
      ) : (
        <ArrowCircleRightIcon />
      )}
    </IconButton>
  );
}