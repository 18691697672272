import React, { useContext } from "react";
import { useParams} from "react-router-dom"
import Loading from "../../Loading";
import "./feedback.css";
import useDB from "../../hooks/useDB";
import Text from "../../components/Text";

import Error from "../../components/Error";
import { IconButton, InputAdornment } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StudentFeedback from "./StudentFeedback";
import SCheckBox from "../../components/SCheckBox";
import mut from "../../hooks/mut";
/* import ContentCopyIcon from "@mui/icons-material/ContentCopy"; */
import { copyToClipboard } from "../../hooks/copyToClipboard";
import {
  ClassesContext,
  StudentsContext,
} from "../../../../Context";
import { format } from "date-fns";
import UpdateUnitAndLesson from "../../components/UpdateUnitAndLesson";



export default function Feedback2(props) {

  const { lessonId } = useParams();
  const [lesson, setLesson, error, loading] = useDB(`/lesson2/${lessonId}`)
  const { findStudent } = useContext(StudentsContext)
  const { findClass } = useContext(ClassesContext)

  if (loading) return <Loading/>;
  if (error) return <Error/>
  const { attendance } = lesson
  console.log(lesson)
  const openLink = (link) => {
    window.open(lesson.lessonPlanLink);
  };

  const copy = () => {
    copyToClipboard(paintFeedback, 400, 1000);
  };

  function paintFeedback(ctx) {
    console.log("blah")
    let line = 30;
    for (let at of attendance.filter(a=>a.attendance<2)) {
      const student = findStudent(at.student);
      const htClass = findClass(at.htClass);
      const margin = 20;
     
      ctx.font = "24px arial";
      ctx.fillStyle = "black";
      ctx.fillText(student.name, margin, line);
      ctx.font = "16px arial";
      ctx.fillStyle = "gray";
      line += 30;
      ctx.fillText(`${htClass.name}`, margin, line);
      line += 20;
      ctx.fillText(
        `${format(new Date(at.classDate), "dd/MM/yyyy")}`,
        margin,
        line
      );
      line += 30;
      ctx.font = "12px arial";
      ctx.fillStyle = "black";
      for (let item in at.feedback) {
        ctx.fillText(item, margin, line);
        line += 5;
        let oneImage = document.getElementById(
          `score${at.feedback[item]}`
        );
        //ctx.fillText(at.feedback[item], margin, line);
        ctx.drawImage(oneImage, margin, line);
        line += 60;
      }
      line += 10;
      if (at.notes) {
        ctx.font = "bold 14px arial";

        ctx.fillText("Nhận xét buổi học", margin, line);
        line += 20;
        ctx.font = "14px arial";
        line = wrapText(ctx, at.notes, margin, line, 360, 24);
        // ctx.fillText(at.notes, 50, line);
        line += 20;
      }

      line += 10;
      if (at.homeWorkNotes) {
        ctx.font = "bold 14px arial";
        ctx.fillText("Bài tập về nhà", margin, line);
        line += 20;
        ctx.font = "14px arial";
        line = wrapText(ctx, at.homeWorkNotes, margin, line, 360, 24);
        line+=40
      }
    }
    return line+20;
  }

  const cl = lesson.cLesson || undefined

  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 style={{ fontWeight: 200 }}>{lesson.classId.name}</h1>

            {/* <UnitLesson
              htClass={lesson.classId}
              setLesson={setLesson}
            /> */}
            <UpdateUnitAndLesson
              htClass={lesson.classId}
              setHtClass={(htClass) =>
                setLesson((prev) => ({
                  ...prev,
                  classId: { ...prev.classId, ...htClass },
                }))
              }
            />
          </div>
          {cl && (
            <div>
              <div>{cl.syllabus.name}</div>
              <div>Unit {cl.unit.unitNumber}: {cl.unit.name}</div>
              <div>Lesson {cl.lessonNumber}: {cl.name}</div>
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Text
                label="Lesson sheet/plan link"
                fullWidth
                value={lesson.lessonPlanLink}
                setValue={(lessonPlanLink) =>
                  setLesson({ ...lesson, lessonPlanLink })
                }
                field="lessonPlanLink"
                url={`/lesson/${lessonId}`}
                sx={{ mt: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="open link in new window"
                        onClick={(e) => openLink()}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <SCheckBox
              label="Homework sent"
              value={lesson.homeworkSent ? true : false}
              setValue={(e) => {
                let update = e
                  ? { homeworkSent: new Date() }
                  : { $unset: { homeworkSent: "" } };
                mut("post", `/lesson/${lesson._id}`, update);
                setLesson((prev) => ({
                  ...prev,
                  homeworkSent: e ? new Date() : false,
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 5,
          fontWeight: 200,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Feedback for students</div>

          {/*   <div>
            Copy all
            <IconButton>
              <ContentCopyIcon onClick={copy} />
            </IconButton>
          </div> */}
        </div>
      </div>
      {attendance.map((at) => (
        <StudentFeedback
          attendance={at}
          key={attendance._id}
          setLesson={setLesson}
        />
      ))}
    </div>
  );
}

/* function DrawBook(props) {
  let { book, updateBookUnit, updateBookLesson, updateLessonBooks } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
      <div>{book.name}</div>
      <TextField
        type="number"
        label="Unit"
        size="small"
        sx={{ mt: 1 }}
        onChange={(e) => updateBookUnit(book._id, e.target.value)}
        onBlur={() => updateLessonBooks()}
        value={book.unit}
      />
      <TextField
        type="number"
        label="Lesson"
        size="small"
        sx={{ mt: 1 }}
        onChange={(e) => updateBookLesson(book._id, e.target.value)}
        onBlur={() => updateLessonBooks()}
        value={book.lesson}
      />
    </div>
  );
}

function getBooks(lesson) {
  if (lesson.lessonBooks) return lesson.lessonBooks;
  if (lesson.classId.books && lesson.classId.books.length > 0)
    return lesson.classId.books;

  return [];
} */


/* function UnitLesson({htClass,setLesson}) {
  
  return (
    <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
      <Text
        label="Unit"
        value={htClass.currentUnit || ""}
        type="number"
        onChange={(e) => {
          setLesson((prev) => {
            let { classId } = prev;
            classId.currentUnit = e.target.value;
            return { ...prev, classId };
          });
        }}
        onBlur={()=>  net.post(`/classes/${htClass._id}/update`,{currentUnit: htClass.currentUnit})} 
        size="small"
        style={{ width: 80 }}
      />
      <Text
        label="Lesson"
        value={htClass.currentLesson || ""}
        type="number"
        onChange={(e) => {
          setLesson((prev) => {
            let { classId } = prev;
            classId.currentLesson = e.target.value;
            return { ...prev, classId };
          });
        }}
        onBlur={() =>
          net.post(`/classes/${htClass._id}/update`, {
            currentLesson: htClass.currentLesson,
          })
        }
        size="small"
        style={{ width: 80 }}
      />
    </div>
  );
} */

 function wrapText(context, text, x, y, maxWidth, lineHeight) {
   var words = text.split(" ");
   var line = "";

   for (var n = 0; n < words.length; n++) {
     var testLine = line + words[n] + " ";
     var metrics = context.measureText(testLine);
     var testWidth = metrics.width;
     if (testWidth > maxWidth && n > 0) {
       context.fillText(line, x, y);
       line = words[n] + " ";
       y += lineHeight;
     } else {
       line = testLine;
     }
   }
   context.fillText(line, x, y);
   return y;
 }