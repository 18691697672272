import { MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import { weekdays } from "../../../enums";
import { LanguageContext } from "../../../Context";

/**
 * A choice component for selecting a day of the week.
 * 
 * @param {object} props props to pass to the TextField component
 * @param {number} props.value the selected value
 * @param {function} props.setValue a function to call with the new value, returns an event
 * @returns a JSX element
 */
export default function DayOfWeekChoice(props) {
  let { value, setValue } = props;
  const {language} = useContext(LanguageContext)
  return (
    <TextField {...props} select value={value} onChange={(e) => setValue(e)}>
      {weekdays.map((day, i) => (
        <MenuItem value={i} key={`day${i}`}>
          {day[language]}
        </MenuItem>
      ))}
    </TextField>
  );
}
