import React from "react";
import { net } from "../../../../database";
import CustomersWithNoNextBillDate from "./components/CustomersWithNoNextBillDate";
import StudentLink from "../../components/StudentLink";
import FormatDate from "../../components/FormatDate";
import Status from "../../components/Status";

export default function Admin(props) {
  let { language, l } = props;
  let [studentsWithNoNextBillDate, setStudentsWithNoNextBillDate] =
    React.useState([]);
  let [oldNBD, setOldNBD] = React.useState([]);
  let [changes,setChanges] = React.useState([]);
  React.useEffect(() => {
    net.get("/admin").then((admin) => {
      setStudentsWithNoNextBillDate(admin.studentsWithNoNextBillDate);
      setOldNBD((prev) => admin.studentsWithOldNextBillDate);
      setChanges(prev=> admin.recentChanges)
    });
  }, []);

  return (
    <div>
      <h1>{l.admin[language]}</h1>
      <h3>Recent changes</h3>
      <RecentChanges students={changes} />
      <h3>{l.studentsWithNoNextBillDateRecorded[language]}</h3>
      {studentsWithNoNextBillDate.map((student) => (
        <CustomersWithNoNextBillDate
          key={student._id}
          student={student}
          language={language}
          l={l}
        />
      ))}
      <h3>Active Students with old next bill date</h3>
      {oldNBD
        .sort((a, b) =>
          a.nextBillDate > b.nextBillDate
            ? 1
            : b.nextBillDate > a.nextBillDate
            ? -1
            : 0
        )
        .map((student) => (
          <div
            key={student._id}
            style={{ display: "flex" }}
          >
            <div style={{ width: 400 }}>
              <StudentLink
                student={student}
                showClasses
              />
            </div>
            <FormatDate value={student.nextBillDate} />
          </div>
        ))}
    </div>
  );
}

function RecentChanges(props) {
  const {students} = props;
  return <div>
    {students.map((student) => (<Change student={student} />))}
  </div>
}

function Change(props) {
  const { student } = props;
  let last = student.statusHistory[student.statusHistory.length - 1]
  return (
    <div style={{display:"flex"}}>
      <div style={{ width: 300 }}>
        {" "}
        <StudentLink student={student._id} showClasses />
      </div>

      <Status status={last.status} />
    </div>
  );
}