import { IconButton, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import Lang from "./Lang";


/**
 * A search bar component that allows a user to type in a search value
 * and call a function to set the search value when the user types.
 *
 * The component will also display a cancel button when the user has typed
 * something in, and will clear the search value if the cancel button is
 * clicked.
 *
 * @param {string} search The current search value
 * @param {function} setSearch A function to set the search value
 * @param {string} [type=text] The type of the TextField
 */

export default function Search(props) {
  const { search, setSearch, type="text" } = props;
  let newProps = { ...props }
  delete newProps.search
  delete newProps.setSearch
  const placeholder = "Search..."
  return (
    <TextField
      value={search}
      type={type}
      onChange={(e) => setSearch(e.target.value)}
      placeholder={placeholder}
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: 2 }} />,
        endAdornment: (
          <IconButton
            disabled
            {...(search !== "" && {
              disabled: false,
              color: "error",
            })}
            onClick={() => setSearch("")}
          >
            <CancelIcon />
          </IconButton>
        ),
      }}
      {...newProps}
    />
  );
}
