import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React, { useContext, useState } from "react";
import Lang from "../../../components/Lang";
import { CenterContext, ClassesContext } from "../../../../../Context";
import SelectFromListField from "../../../components/SelectFromListField";
import TimeClock from "../../../components/TimeClock";
import RoomChoice from "../../../components/RoomChoice";
import TeacherChoiceObject from "../../../components/TeacherChoiceObject";
import mut from "../../../hooks/mut";
export default function AddClassDialog(props) {
    const { open, setOpen, todaysClasses } = props;
    const { classes } = useContext(ClassesContext)
    const [lesson,setLesson] = useState({htClass:"", startTime:"",endTime:"", room:"", teacher:""})
    const {center} = useContext(CenterContext)
    const location = localStorage.getItem("location")
      ? center.locations[localStorage.getItem("location")]
      : center.locations[0]; ;

    const add = async () => {
        let res = await mut("post", "/lesson-add", { ...lesson, location: location._id })
        todaysClasses.add(res)
        setLesson({
          htClass: "",
          startTime: "",
          endTime: "",
          room: "",
          teacher: "",
        });
        setOpen(false)
    }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Lang w="addAnExtraClassToday" />
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          padding: 20,
        }}
      >
        <SelectFromListField
          choices={classes
            .filter(
              (c) =>
                c.location === location._id &&
                ["Active", "Tutoring"].includes(c.status) &&
                !todaysClasses.map(l=>l.htClass).includes(c._id)
            )
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
          object={lesson}
          setObject={setLesson}
          field="htClass"
          fullWidth
          label={<Lang w="class" />}
        />
        <RoomChoice
          location={location._id}
          value={lesson.room}
          setValue={(room) => setLesson((prev) => ({ ...prev, room }))}
          fullWidth
        />
        <TeacherChoiceObject
          object={lesson}
          setObject={setLesson}
          fullWidth
        />
        <div style={{ display: "flex", gap: 5 }}>
          <TimeClock
            value={lesson.startTime}
            setValue={(startTime) =>
              setLesson((prev) => ({ ...prev, startTime }))
            }
            label="startTime"
          />
          <TimeClock
            value={lesson.endTime}
            setValue={(endTime) => setLesson((prev) => ({ ...prev, endTime }))}
            label="finishingTime"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
              <Button
                  color="success"
                  disabled={!lesson.htClass || !lesson.room || !lesson.teacher || !lesson.startTime || !lesson.endTime}
                onClick={add}
              >Go</Button>
      </DialogActions>
    </Dialog>
  );
}