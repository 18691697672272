import mut from "./mut";

/**
 * 
 * @description Function to debounce to a url, place in useEffect
 * @param {string} url url to send the data to
 * @param {string} field name of the field to update
 * @param {*} data data to send to the url   
 * @param {boolean} first state prevent updating on the first render, set to true after the debounce call 
 * @param {number} delay optional time before sending, default 1000        
 * @returns {function} function which clears the timeout, return this from the useEffect
    @example 
    const [first, setFirst] = useState(false);  // don't fire debounce on first render
    useEffect(() => {
    let db = debounce(url, field, value, delay, first);
    setFirst(true);
    return db  
  }, [value]);
 */

export function debounce(url, field, data, first, delay = 1000, http="post") {
  if (url && field && first) {
    const getData = setTimeout(() => {
      mut(http, url, { [field]: data });
    }, delay);

    return () => clearTimeout(getData);
  }
}
