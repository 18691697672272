import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React, { useContext, useState } from "react";
import SelectFromList from "../../../components/SelectFromList";
import { StaffContext } from "../../../../../Context";
import mut from "../../../hooks/mut";
import replaceById from "../../../hooks/replaceById";
import DayOfWeek from "../../../components/DayOfWeek";
import TimeClock from "../../../components/TimeClock";
import DifferenceInHours from "../../../components/DifferenceInHours";
import RadioGroupHT from "../../../components/RadioGroupHT";



export default function NewForeignTeacherDialog(props) {
  const { open, setOpen,htClass,classDay,setClasses,week, day } = props;
  const { getStaffRole } = useContext(StaffContext)
  const [future, setFuture] = useState("allFutureClasses")
  const [ft,setFT] = useState({foreignTeacher: htClass.foreignTeacher || "", FTStartTime: htClass.FTStartTime || "", FTEndTime: htClass.FTEndTime || ""})
  return (
    <Dialog open={open}>
      <DialogTitle>
        Foreign teacher for {htClass.name} on{" "}
        <DayOfWeek day={htClass.dayOfWeek} />
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <RadioGroupHT
            choices={["allFutureClasses", "thisClass"]}
            value={future}
            setValue={setFuture}
            label={"changeFor"}
          />
          <SelectFromList
            choices={getStaffRole("Foreign Teacher")}
            value={ft.foreignTeacher}
            setValue={(foreignTeacher) =>
              setFT((prev) => ({ ...prev, foreignTeacher }))
            }
            fullWidth
          />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <TimeClock
              value={ft.FTStartTime}
              setValue={(FTStartTime) =>
                setFT((prev) => ({ ...prev, FTStartTime }))
              }
              label="startTime"
            />
            <TimeClock
              value={ft.FTEndTime}
              setValue={(FTEndTime) =>
                setFT((prev) => ({ ...prev, FTEndTime }))
              }
              label="finishingTime"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DifferenceInHours
              startTime={ft.FTStartTime}
              endTime={ft.FTEndTime}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
          color="success"
          onClick={async() => {
            let res = await mut("post", `/lesson-add-ft/${htClass._id}/${future}`, {
              ...ft,
              htClass: htClass.htClass,
              dayOfWeek: day,
              tDate: htClass.tDate,
            });
         
            replaceById(setClasses, res)
            setOpen(false)
          }}
          disabled={
            ft.foreignTeacher &&
            ft.FTStartTime &&
            ft.FTEndTime
              ? false
              : true
          }
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}