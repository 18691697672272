import React from "react";
import ClassView from "./ClassView";
import Time from "../../../components/Time";
import PrintTimetable from "./PrintTimetable";

export default function ListClasses({classes, setSelected}) {
  let startTimes = [
    ...new Set(
      classes.map((c) => c.startTime.substring(0,5)
      )
    ),
  ];
  startTimes.sort();

  return (
    <div>
      {startTimes.map((st) => (
        <div key={st}>
          <div
            style={{
              fontSize: 12,
              padding: 1,
              textAlign: "center",
              background:
                "linear-gradient(49deg, rgba(2,0,36,1) 0%, rgba(191,191,191,1) 0%, rgba(237,237,237,1) 100%)",
              borderRadius: 5,
            }}
          >
            <Time time={st} />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {classes
              .filter((c) => c.startTime.substring(0,5) === st
              )
              .map((c) => (
                <div key={c._id} style={{ marginRight: 3 }}>
                  <ClassView
                    htClass={c}
                    setSelected={setSelected}
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
       <div id="printDiv" style={{  display: "none"}}>
        <PrintTimetable classes={classes} startTimes={startTimes}  />

      </div>
    </div>
  );
}
