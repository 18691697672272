import React, { useState, useEffect } from "react";
import "./Clock.css";
import Lang from "./Lang";

const TimeClock = ({
  value = "00:00",
  setValue,
  width = 200,
  height = 200,
  label = "",
}) => {
  const [clockNumbers, setClockNumbers] = useState([]);
  const [count, setCount] = useState(true);
  const [pm, setPm] = useState(true);

  const [hours, minutes] = value.split(":")

  useEffect(() => {
    const radius = 38; // Reduce the radius for number positioning
    const clockNumbers = Array.from({ length: 12 }, (_, i) => {
      const angle = ((i + 1) / 12) * 2 * Math.PI; // Convert to radians
      const x = 50 + radius * Math.cos(angle - Math.PI / 2); // Calculate x
      const y = 50 + radius * Math.sin(angle - Math.PI / 2); // Calculate y
      return {
        number: count ? i + 1 : i === 11 ? "00" : (i + 1) * 5,
        style: {
          left: `${x}%`,
          top: `${y}%`,
          transform: "translate(-50%, -50%)",
          /*  backgroundColor:
            hours === i + 1 || (hours === 12 && i === 0) ? "red" : "", */
        },
      };
    });
    setClockNumbers(clockNumbers);
  }, [hours, count, pm]);

  const handleNumberClick = (index) => {
    let hour = count ? index + 1 : hours;
    if (!pm && count && hour === 12) hour = 0;
    if (pm && count && hour < 12) hour += 12;
    const minute = !count ? (index === 11 ? "00" : (index + 1) * 5) : minutes;
 
    setValue(`${hour}:${minute ? String(minute.toString().padStart(2, "0")) : "00"}` 
    );
    setCount(!count);
  };

  const hourHandStyle = {
    transform: `rotate(${(hours % 12) * 30}deg)`, // 360 degrees / 12 hours = 30 degrees per hour
  };

  const minuteHandStyle = {
    transform: `rotate(${minutes * 6}deg)`, // 360 degrees / 60 minutes = 6 degrees per minute
  };
  return (
    <div>
      {label && (
        <div className="clock-label">
          <Lang w={label} />
        </div>
      )}
     {/*  <div
        className="clock-border"
        style={{ width, height }}
      > */}
        <div
          className="clock-face"
          style={{ width:width, height:height }}
        >
          {clockNumbers.map((num, index) => (
            <div
              key={index}
              className="number"
              style={num.style}
              onClick={() => handleNumberClick(index)}
            >
              {num.number}
            </div>
          ))}
          <div
            className="hour-hand"
            style={hourHandStyle}
          ></div>
          <div
            className="minute-hand"
            style={minuteHandStyle}
          ></div>
          <div
            className="ampm"
            onClick={() => setPm(!pm)}
          >
            {pm ? "PM" : "AM"}
          </div>

          {hours && minutes && (
            <div className="clock-time">
              {" "}
              {pm && hours !== 12 ? hours - 12 : hours}:
              {minutes < 10 && minutes > 0 ? `0${minutes}` : minutes} {pm ? "PM" : "AM"}
            </div>
          )}
        </div>
    {/*   </div> */}
    </div>
  );
};

export default TimeClock;
