/**
 * @description Replace an object in an array by another with the same _id, the objects will be merged with new information overriding the old
 * @param {function} setFunction React setter function
 * @param {object} newObject Object to replace in the array
 */
export default function replaceById(setFunction, newObject) {
  setFunction((prev) =>
      prev.map((p) => (p._id === newObject._id ? ({ ...p, ...newObject }) : p))
  );
}
