import React, { useContext } from "react";
import { CenterContext } from "../../../Context";
import SelectFromList from "./SelectFromList";
import Lang from "./Lang";


export default function RoomChoice(props) {
    const { location, value, setValue,fullWidth } = props
    const { center } = useContext(CenterContext)
    const l = center.locations.find(l => l._id === location)
    if(!location) return <SelectFromList choices={["none"]} value={value} setValue={setValue} />

    return (<SelectFromList choices={l.rooms} label={<Lang w="room" />} {...props} />)
}