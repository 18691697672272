import { differenceInMinutes } from "date-fns";
import React, { useEffect, useState } from "react";
import Lang from "./Lang";


export default function DifferenceInHours({ startTime, endTime, style={} }) {
    const [hours,setHours] = useState(0)

   

      useEffect(() => {
            const hrs = (differenceInMinutes(new Date("2024-01-01 "+endTime), new Date("2024-01-01 "+startTime))/60).toFixed(2)
            setHours(hrs)
        },[startTime,endTime])
    if (!startTime || !endTime) return <></>;
    
    if(hours < 0.1) style={...style, color:"red"}

    return (<div style={style}><Lang w="hours" />: {hours}</div>)
}