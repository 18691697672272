
export const menu = [
  {
    title: "Home",
    items: [
      {
        name: "home",
        href: "/",
        icon: "home",
      },
      {
        name: "timetable",
        href: "/timetable",
        icon: "timetable",
      },
      {
        name: "attendance",
        href: "/attendance",
        icon: "attendance",
      },
    ],
  },
  /*  {
    title: "Head Teachers",
    access: "Head Teacher",
    items: [
      {
        name: makeupClasses,
        href: "manage/makeupclasses/timetable",
        icon: "attendance",
      },
      {
        name: ["Extra classes", "Extra classes"],
        href: "/extra-classes",
        icon: "student",
      },
      { name: feedback, href: "/manage/feedback", icon: "feedback" },
      { name: staff, href: "/manage/staff", icon: "staff" },
    ],
  }, */
  {
    title: "Admin",
    access: "Admin",
    items: [
      {
        name: "staff",
        href: "/staffshifts",
        icon: "attendance",
      },
      { name: "money", href: "/money", icon: "money" },
      { name: "owed", href: "/owed/1", icon: "money" },
      { name: "absent", href: "/absent", icon: "student" },
      { name: "feedback", href: "/manage/feedback", icon: "feedback" },
      /*  { name: ["New Absent","New Absent
             ,
             
              { name:["Books","Books"], href: "/admin/Books", icon: "home" },
              ], href: "/absent2", icon: "student" }, */

      { name: "care", href: "/care", icon: "student" },
      { name: "admin", href: "/admin", icon: "attendance" },
      {
        name: "ftTimetable",
        href: "/admin/fttimetable",
        icon: "home",
      },
      {
        name: "books",
        href: "/admin/Books",
        icon: "book",
      },
      {
        name: "classesList",
        href: "/classlist",
        icon: "student",
      },
      { name: "syllabusList", href: "/tech/notifications", icon: "home" },
      /*  {
        name: ["Extra classes", "Extra classes"],
        href: "/extra-classes",
        icon: "student",
      }, */
      /*   {
        name: ["Low attendance", "Low attendance"],
        href: "/student-data",
        icon: "student",
      }, */
      /*   {
        name: ["Tests", "Tests"],
        href: "/tests",
        icon: "student",
      }, */
      /*  { name: ["Next payment","Next payment"], href: "/sales/next-payment", icon: "money" } */
      /*  {
        name: billing,
        href: "/accounting/billing",
        icon: "money",
      }, */
      /*  { name: students, href: "/students", icon: "student" }, */
    ],
  },
  /* {
    title: "Teaching",
    items: [
      { name: feedback, href: "/feedback", icon: "feedback" },
      {
        name: myStudents,
        href: "/mystudents",
        icon: "student",
      },
    ],
  }, */
  {
    title: "Sales",
    access: "Sales",
    items: [
      {
        name: "messages",
        href: "/sales/messages",
        icon: "comments",
      },
      {
        name: "marketing",
        href: "/marketing",
        icon: "call",
      },
      {
        name: "paymentFrequency",
        href: "/sales/payment-frequency",
        icon: "comments",
      },
      /*  {
        name: bills,
        href: "/billcustomers",
        icon: "money",
      }, */
      {
        name: "data",
        href: "/sales/students",
        icon: "attendance",
      },
      {
        name: "potentials",
        href: "sales/leads",
        icon: "attendance",
      },
      {
        name: "customerCare",
        href: "sales/care",
        icon: "student",
      },
      { name: "newLost", href: "manage/newlost", icon: "student" },

      /*  {
        name: makeupClasses,
        href: "manage/makeupclasses/timetable",
        icon: "attendance",
      }, */
      /*  {
        name: testing,
        href: "/sales/testing",
        icon: "attendance",
      },
      {
        name: feedback,
        href: "sales/feedback",
        icon: "attendance",
      },
*/
      {
        name: "schedule",
        href: "sales/newclasses",
        icon: "attendance",
      },
      {
        name: "pending",
        href: "pending",
        icon: "attendance",
      },
    ],
  },
  {
    title: "Accounting",
    access: "Accountant",
    items: [
      /*  { name: accounting, href: "accounting", icon: "attendance" }, */
      { name: "salaries", href: "/manage/salaries", icon: "staff" },
      { name: "costs", href: "/manage/costs", icon: "money" },
      /*   {
        name: fixBills,
        href: "/fixbills",
        icon: "money",
      }, */

      { name: "financial", href: "/accounting/financial", icon: "home" },
    ],
  },
  {
    title: "Human resources",
    access: "Human resources",
    items: [
      { name: "humanResources", href: "/manage/humanResources", icon: "home" },
    ],
  },
  {
    title: "Manage",
    access: "Manager",
    items: [
      { name: "overview", href: "manage/overview", icon: "home" },
      { name: "staff", href: "/manage/staff", icon: "staff" },
      { name: "costs", href: "/manage/costs", icon: "money" },
      { name: "settings", href: "/manage/settings", icon: "settings" },

      { name: "marketing", href: "/manage/marketing", icon: "call" },
      { name: "sales", href: "/manage/sales", icon: "money" },
      {
        name: "pickupService",
        href: "/manage/pickup-service",
        icon: "bike",
      },
      {
        name: "revenueToday",
        href: "/tech/revenue-today",
        icon: "staff",
      },
      {
        name: "careReport",
        href: "/manage/care",
        icon: "call",
      },
      {
        name: "aiFeedback",
        href: "/manage/ai-feedback",
        icon: "staff",
      },
    ],
  },
  /* {
    title: "Owner",
    access: "Owner",
    items: [
      { name: staffList, href: "/owner/stafflist", icon: "staff" },
      {
        name: "overview",
        href: "/manage/overview2",
        icon: "staff",
      },

       { name: staffTree, href: "/owner/stafftree", icon: "staff" }, 
    ],
  }, */
  {
    title: "Tech",
    access: "Tech",
    items: [
      {
        name: "routeRecord",
        href: "/tech/routerecord",
        icon: "staff",
      },

      { name: "projection", href: "/tech/projection", icon: "home" },

      { name: "syllabus", href: "/teaching/syllabus", icon: "home" },

      { name: ["timetable", "timetable"], href: "/tech/owed2", icon: "home" },
    ],
  },
];


 /* { name: ["stats","stats"], href: "/manage/overview2/addsalarystat", icon: "staff" } */

      /*  { name: staffTree, href: "/owner/stafftree", icon: "staff" }, */