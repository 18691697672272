import React, { useContext, useState } from "react";
import "./absent.css";
import Loading from "../../Loading";
import { Grid, } from "@mui/material";
import { format } from "date-fns";
import Lang from "../../components/Lang";
import HtClass from "../../components/HtClass";
import SLink from "../../components/SLink";
import HtTabs from "../../components/HtTabs";
import { CenterContext} from "../../../../Context";
import useDBO from "../../hooks/useDBO";
import Text from "../../components/Text";
import DatePicker from "../../components/DatePicker";
import mut from "../../hooks/mut";
import Staff from "../../components/Staff";

export default function Absent() {

  const { center } = useContext(CenterContext)
  const [dt,setDt] = useState(new Date())
  const lessons = useDBO(`/absent/${dt.tDate()}`);
  console.log(lessons)
  const labels = center.locations.filter(l=>l.name!=="All locations").map(l => l.name)
  const components = center.locations.filter(l=>l.name!=="All locations").map(l => <DrawAbsentLocation lessons={lessons} location={l._id} />)
 
  return (
    <div style={{ padding: 10 }}>
      <div className="dflex-sb">
        <h1>
          <Lang w="absentStudents" />
        </h1>
      </div>

      <HtTabs
        labels={labels}
        components={components}
        localStorage="location"
        nextToTabs={
          <DatePicker
            value={dt}
            setValue={setDt}
            buttons
            disabled={lessons.loading}
            align="center"
          />
        }
      />
    </div>
  );
}

function DrawAbsentLocation({lessons,location}) {

if(lessons.loading) return <Loading />
 
/*   const { findClass } = useContext(ClassesContext)
    if (absences.loading) return <Loading />;
  let filClasses = []
   let htClasses = [...new Set(absences.map((a) => a.htClass))];
  for (let htc of htClasses) {
    let c = findClass(htc)
    if(c.location===location) filClasses.push(htc)
  } */

  return (
    <div>
      <Grid
        container
        className="absent-table-labels"
      >
        <Grid
          item
          xs={3}
        >
          <Lang w="name" />
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Lang w="type" />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Lang w="reason" />
        </Grid>
      </Grid>
      {lessons
        .filter((l) => l.absentReasons.length > 0 && l.location === location)
        .map((lesson) => (
          <div>
            <h3>
              <HtClass
                htClass={lesson.htClass}
                link
              />
            </h3>

            {lesson.absentReasons.map((absence) => (
              <Grid
                container
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid
                  item
                  xs={3}
                >
                  <SLink
                    id={absence.student}
                    showPhone
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    color: absence.attendance === 2 ? "#ffae00" : "#ff0055",
                  }}
                >
                  {absence.attendance === 2 ? "P" : "O"}
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  {absence.attendance < 3 ? (
                    <div>
                      <div>{absence.reason}</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: 11,
                          width: 200,
                          color: "#888",
                        }}
                      >
                        <Staff id={absence.recordedBy} />
                        <div>{absence.time ? format(new Date(absence.time), "hh:mm") : "No time"}</div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Text
                        value={absence.reason || ""}
                        type="text"
                        setValue={(reason) => {
                          const absentReasons = lesson.absentReasons.map((ar) =>
                            ar._id === absence._id ? { ...absence, reason } : ar
                          );
                          lessons.update({ _id: lesson._id, absentReasons });
                        }}
                        onBlur={async () => {
                          await mut(
                            "post",
                            `/lesson-absent-reason/${lesson._id}`,
                            { student: absence.student, reason: absence.reason }
                          );
                        }}
                        size="small"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: 11,
                          width: 200,
                          color: "#888",
                        }}
                      >
                        <Staff id={absence.recordedBy} />
                        <div>{absence.time ? format(new Date(absence.time), "hh:mm") : "No time"}</div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            ))}
          </div>
        ))}
    </div>
  );
}