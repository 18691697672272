import React, { useContext } from "react";
import { net } from "../../../../database.js";
import { DataGrid } from "@mui/x-data-grid";
import {
  IconButton,
  TextField,
  Tooltip,
  InputAdornment,
  Button,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import "./owed.css";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CallIcon from "@mui/icons-material/Call";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";



import NumberFormat from "react-number-format";

import { differenceInDays } from "date-fns";

import { format } from "date-fns";
import PaymentDialog from "./components/PaymentDialog.jsx";
import ContactDialog from "./components/ContactDialog.js";
import { exportFile } from "table-xlsx";
import { formatPhone } from "../billCustomers/functions.js";
import StudentLink from "../../components/StudentLink.js";
import PhoneNumber from "../../components/PhoneNumber.jsx";

import {copyBillsToClipboard} from "../../components/copyToClipboard.js"
import Lang from "../../components/Lang.jsx";
import { CenterContext, LanguageContext } from "../../../../Context.jsx";
import useDBO from "../../hooks/useDBO.js";
import Error from "../../components/Error.js";
import Loading from "../../Loading.jsx";
import VND from "../../components/VND.jsx";
import HtClass from "../../components/HtClass.jsx";


/* var format = require("date-format"); */

export default function Owed2(props) {
  const { center } = useContext(CenterContext)
  const {l, language} = useContext(LanguageContext)
  const rows = useDBO("/customers-owe")
  const [openContactDialog, setOpenContactDialog] = React.useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [customerId, setCustomerId] = React.useState(undefined);
    const [contact, setContact] = React.useState({
      notes: "",
      followUp: "",
      customer: undefined,
    });

  if (rows.loading) return <Loading />
  if (rows.error) return <Error />;
  console.log(rows)

  const closePaymentDialog = (customer) => {
    setCustomerId(undefined);
    setOpenContactDialog(false);
  };

  const getColumns = () => {
    return [
      {
        field: "name",
        headerName: <Lang w="name" />,
        renderCell: (props) => (
          <Link to={`/customers/${props.id}`} className="neat"> {props.value} </Link>
        ),
      },
      {
        field: "phone",
        headerName: <Lang w="phone" />,
        renderCell: (props) => <PhoneNumber value={props.value} />,
      },
      {
        field: "amount",
        headerName: <Lang w="amount" />,
        renderCell: (props) => <VND value={props.value} />,
      },
      {
        field: "students",
        headerName: <Lang w="students" />,
        width: 300,
        renderCell: (props) => (
          <div>
            {props.value.map(s => <div><Link to={`/student/${s._id}`} className="neat">{s.name}</Link> {s.classes.map(id=><HtClass htClass={id} link/>)}</div>)}
          </div>
        ),
      },

       {
        field: "payment",
        headerName: <Lang w="actions"/>,
        width: 130,
        headerClassName: "lastcolumnSeparator",

        renderCell: (props) => (
          <div>
            <Tooltip title="Copy">
              <IconButton
                onClick={() => {
                  setContact({ ...contact, customer: props.id });
                  setOpenCopy(true);
                  setCustomerId(props.id); 
               
                   copyBillsToClipboard(props.row);
            
                }}
              >
                <ContentCopyIcon sx={{ fontSize: "0.8rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Payment">
              <IconButton
                component="button"
                onClick={(e) => {
                  setCustomerId(props.id);
                  setOpenPaymentDialog(true);
                  setContact({ ...contact, customer: props.id });
                }}
              >
                <AttachMoneyIcon sx={{ fontSize: "0.8rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Contact">
              <IconButton
                component="button"
                onClick={(e) => {
                  setCustomerId(props.id);
                  setContact({ ...contact, customer: props.id });
                  setOpenContactDialog(true);
                }}
              >
                <CallIcon sx={{ fontSize: "0.9rem" }} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }, 
    ];
  };

  const columns = getColumns();

/*   const onExportFileClick = () => {
    let out = rows.map((row) => ({
      ...row,
      phone: formatPhone(row.phone),
      zalo: formatPhone(row.phone2),
      students: row.students.map((s) => s.name).toString(),
      lastPayment: row.lastPayment
        ? format(new Date(row.lastPayment), "dd/MM/yyyy")
        : "None",
      oldestBill: row.oldestBill
        ? format(new Date(row.oldestBill), "dd/MM/yyyy")
        : "None",
      lastContact: row.lastContact
        ? format(new Date(row.lastContact), "dd/MM/yyyy")
        : "None",
      followUp: row.followUp
        ? format(new Date(row.followUp), "dd/MM/yyyy")
        : "None",
    }));
    console.log(out);
    exportFile({
      columns: getColumns(),
      dataSource: out,
      fileName: `owed.xlsx`,
    });
  }; */

  const contactCustomer = () => {
    let a = {
      notes: contact.notes,
      followUp: contact.followUp,
    };
    console.log(contact);
    net
      .post(`/customers/${contact.customer}/contacts/add`, a)
      .then((customer) => {
        console.log(customer);
        let newRow = makeNewRow(customer);
       /*  setRows(rows.map((r) => (r.id === newRow.id ? newRow : r))); */

        setOpenContactDialog(false);
      });
  };

  let customer = rows.find((r) => r.id === customerId);

  const filter = (row) => {
    if (row.name.toLowerCase().includes(search.toLowerCase())) return true;
    if (row.ref.includes(search.toUpperCase())) return true;
    if (row.phone.includes(search)) return true;
    if (row.studentsNames.includes(search.toLowerCase())) return true;
  };
 
 
  return (
    <div style={{ height: "100%" }} id="owed">
      <div className="dflex-sb" style={{ alignItems: "center" }}>
        <h1>{<Lang w="owed" />}</h1>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label={<Lang w="search" />}
          sx={{ width: "15rem" }}
          {...(search.length > 0 && {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="reset select box"
                    onClick={() => setSearch("")}
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          })}
        />
        <div style={{ display: "flex" }}>
          <h1>
            Total:{" "}
            <VND value={rows.reduce("amount")} />
          </h1>
        {/*   <Button onClick={() => onExportFileClick()} sx={{ ml: 1 }}>
            Export
          </Button> */}
        </div>
      </div>
      <DataGrid
        rows={rows.get}
        columns={columns}
        disableSelectionOnClick
        autoPageSize
        sx={{ fontSize: "0.8rem" }}
        rowHeight={40}
      />

     {/*  {customer ? (
        <ContactDialog
          open={openContactDialog}
          setOpen={setOpenContactDialog}
          customer={customer}
          copyBillsToClipboard={copyBillsToClipboard}
          contact={contact}
          setContact={setContact}
          contactCustomer={contactCustomer}
          l={l}
          language={language}
        />
      ) : (
        ""
      )}
      {customer ? (
        <PaymentDialog
          customerId={customer.id}
          open={openPaymentDialog}
          setOpen={closePaymentDialog}
          l={l}
          language={language}
          user={props.user}
          center={center}
          onSuccess={() => setCustomerId(undefined)}
        />
      ) : (
        ""
      )} */}
     {/*  {customer ? <ScreenBills open={openCopy} setOpen={setOpenCopy} customer={customer} /> : null} */}

     {/*  <div id="secretDiv" style={{ display: "none" }}></div> */}
      {/* <div id="secretDiv2" style={{ width:400, height: 500,display: "none" }}>
       
        {customer ? (
          <div>
            {customer.students
              .filter((student) => student.unpaidBills.length > 0)
              .map((student) => (
                <div>
                  <div
                    key={student._id}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Link to={`/student/${student._id}`} className="neat">
                        {" "}
                        {student.name}
                      </Link>
                    </div>
                    <div>
                      <NumberFormat
                        displayType="text"
                        value={student.unpaidBills.reduce(
                          (a, b) => a + b.amount,
                          0
                        )}
                        thousandSeparator
                        style={{ marginRight: 14 }}
                      />
                    </div>
                  </div>
                  {[...new Set(student.unpaidBills.map((b) => b.date))].map(
                    (dates) => (
                      <div key={dates}>
                        <div style={{ fontSize: 11, fontWeight: "bold" }}>
                          {format(new Date(dates), "dd/MM/yyyy")}
                        </div>
                        {student.unpaidBills
                          .filter((b) => b.date === dates)
                          .map((bill) => (
                            <div
                              key={bill._id}
                              className="dflex-sb"
                              style={{ fontSize: 12, marginRight: 14 }}
                            >
                              <div>{bill.description}</div>
                              <div>
                                <NumberFormat
                                  value={bill.amount}
                                  displayType="text"
                                  thousandSeparator
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )
                  )}
                </div>
              ))}
            <br></br>
            <hr />
            <div className="dflex-sb">
              <div>Total: </div>
              <NumberFormat
                value={customer.owed - customer.credit}
                thousandSeparator
                displayType="text"
                style={{ marginRight: 14 }}
              ></NumberFormat>
            </div>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
}

function makeNewRow(customer) {
  let newRow = {
    id: customer._id,
    name: customer.name ? customer.name : "No name",
    phone: customer.phone,
    ref: customer.ref,
    owed: customer.owed - customer.credit,
    students: customer.students,
    studentsNames: customer.students
      .map((s) => s.name.toLowerCase())
      .toString(),
    lastPayment:
      customer.payments.length > 0
        ? new Date(customer.payments[customer.payments.length - 1].date)
        : "",
    payments: customer.payments,
    credit: customer.credit,
    lastContact:
      customer.contactsAboutBills.length > 0
        ? new Date(
            customer.contactsAboutBills[
              customer.contactsAboutBills.length - 1
            ].date
          )
        : "",
    lastContactNotes:
      customer.contactsAboutBills.length > 0
        ? customer.contactsAboutBills[customer.contactsAboutBills.length - 1]
            .notes
        : "",
    followUp:
      customer.contactsAboutBills.length > 0 &&
      customer.contactsAboutBills[customer.contactsAboutBills.length - 1]
        .followUp
        ? new Date(
            customer.contactsAboutBills[
              customer.contactsAboutBills.length - 1
            ].followUp
          )
        : "",
  };
  let billDate = undefined;
  customer.students.forEach((student) => {
    //student.nextBillDate = new Date(student.nextBillDate);
    student.unpaidBills.forEach((bill) => {
      if (new Date(bill.date) < billDate || billDate === undefined)
        billDate = new Date(bill.date);
    });
  });
  newRow.oldestBill = billDate;
  /*  newRow.nextBillDate = customer.nextBillDate
    ? new Date(customer.nextBillDate)
    : new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`);

  newRow.paymentFrequency = customer.paymentFrequency || 1; */
  return newRow;
}
