import React from "react";
import { Box } from "@mui/material";
import ClassView from "./ClassView";
import Time from "../../../components/Time";
import replaceById from "../../../hooks/replaceById";
import mut from "../../../hooks/mut";

export default function ClassesOnDay(props) {
  const { day, setClasses, classes } = props;
 /*  let classes = props.classes.filter(c => c.classDays.find(cd => cd.day === day) && c.location === props.location._id);
  const starttimes = []
  for (let htClass of classes) {
    let cd = htClass.classDays.find(c => c.day === day)
    if(!starttimes.includes(cd.startTime.substring(0,5))) starttimes.push(cd.startTime.substring(0,5))
  } */
 const startTimes = [...new Set(classes.map(c=>c.startTime.substring(0,5)))]


  return (
    <div>
      {startTimes.sort().map((st) => (
        <Box
          key={"startTime" + st}
         
        >
          <div
            style={{
              textAlign: "center",
              padding: "0px",
              paddingTop: "5px",
              fontSize: "12px",

              margin: 0,
            }}
            key={st}
          >
            <Time time={st} />
          </div>
          {classes
            .filter((c) => c.startTime.substring(0, 5) === st)
            .map((cl) => (
              <div key={cl._id}>
                <ClassView
                  htClass={cl}
                  day={day}
                  {...props}
                />
              </div>
            ))}
        </Box>
      ))}
    </div>
  );
}
