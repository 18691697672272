function makeTDate(dt = new Date()) { 
    const year = dt.getFullYear();
    const month = String(dt.getMonth() + 1).padStart(2, "0");
    const day = String(dt.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`
}

/**
 * Adds various utility functions to the Date prototype.
 * 
 * @function
 * @name addDateFunctions
 * 
 * @description
 * This function extends the Date prototype with the following methods:
 * 
 * - `ymd()`: Returns an object with the year, month, and day.
 * - `ymdd()`: Returns an object with the year, month, day, and day of the week.
 * - `ymddt()`: Returns an object with the year, month, day, day of the week, and a custom tDate.
 * - `ymdhms()`: Returns an object with the year, month, day, hour, minute, and second.
 * - `tDate()`: Returns a custom tDate.
 * - `isToday()`: Checks if the date is today.
 * - `startOfWeek()`: Returns the start of the week as a custom tDate.
 * - `startOfWeekAsDate()`: Returns the start of the week as a Date object.
 * - `equals(dateIn)`: Checks if the date is equal to another date.
 * - `fDate()`: Returns the date in the format `DD/MM/YYYY`.
 * - `fTime()`: Returns the time in the format `HH:MM`.
 * - `fDateTime()`: Returns the date and time in the format `DD/MM/YYYY HH:MM`.
 * - `fTimeDate()`: Returns the time and date in the format `HH:MM DD/MM/YYYY`.
 * - `addDays(days)`: Adds a specified number of days to the date.
 * - `addMonths(months)`: Adds a specified number of months to the date.
 * - `inArrayOfTDates(tDates)`: Checks if the date is in an array of custom tDates.
 * - `inArrayOfDates(dates)`: Checks if the date is in an array of Date objects.
 * - `ampm()`: Returns "am" or "pm" based on the hour of the date.
 * - `differenceInMinutes(date)`: Returns the difference in minutes between the date and another date.
 * - `differenceInHours(date)`: Returns the difference in hours between the date and another date.
 */
function addDateFunctions() {

  /**
   * Returns an object with the year, month, and day.
   * @returns {Object} An object with year, month, and day properties.
   */
  Date.prototype.ymd = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
    };
  };

  /**
   * Returns an object with the year, month, day, and day of the week.
   * @returns {Object} An object with year, month, day, and dayOfWeek properties.
   */
  Date.prototype.ymdd = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
      dayOfWeek: this.getDay(),
    };
  };

  Date.prototype.ymddt = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
      dayOfWeek: this.getDay(),
      tDate: makeTDate(this),
    };
  };

  /**
   * Returns an object with the year, month, day, hour, minute, and second.
   * @returns {Object} An object with year, month, day, hour, minute, and second properties.
   */
  Date.prototype.ymdhms = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
      hour: this.getHours(),
      minute: this.getMinutes(),
      second: this.getSeconds(),
    };
  };

  /**
   * Returns a custom tDate.
   * @returns {string} The custom tDate.
   */
  Date.prototype.tDate = function () {
    return makeTDate(this);
  };

  /**
   * Checks if the date is today.
   * @returns {boolean} True if the date is today, false otherwise.
   */
  Date.prototype.isToday = function () {
    const today = new Date();
    return (
      this.getDate() === today.getDate() &&
      this.getMonth() === today.getMonth() &&
      this.getFullYear() === today.getFullYear()
    );
  };

  /**
   * Returns the start of the week as a custom tDate.
   * @returns {string} The start of the week as a custom tDate.
   */
  Date.prototype.startOfWeek = function () {
    const startOfWeekDate = new Date(this);
    if (startOfWeekDate.getDay() === 0) {
      startOfWeekDate.setDate(startOfWeekDate.getDate() - 6);
    } else {
      startOfWeekDate.setDate(
        startOfWeekDate.getDate() - (startOfWeekDate.getDay() - 1)
      );
    }
    return makeTDate(startOfWeekDate);
  };

  /**
   * Returns the start of the week as a Date object.
   * @returns {Date} The start of the week as a Date object.
   */
  Date.prototype.startOfWeekAsDate = function () {
    const startOfWeekDate = new Date(this);
    if (startOfWeekDate.getDay() === 0) {
      startOfWeekDate.setDate(startOfWeekDate.getDate() - 6);
    } else {
      startOfWeekDate.setDate(
        startOfWeekDate.getDate() - (startOfWeekDate.getDay() - 1)
      );
    }
    return startOfWeekDate;
  };

  /**
   * Checks if the date is equal to another date.
   * @param {Date} dateIn - The date to compare to.
   * @returns {boolean} True if the dates are equal, false otherwise.
   */
  Date.prototype.equals = function (dateIn) {
    return (
      this.getFullYear() === dateIn.getFullYear() &&
      this.getMonth() === dateIn.getMonth() &&
      this.getDate() === dateIn.getDate()
    );
  };

  /**
   * Returns the date in the format DD/MM/YYYY.
   * @returns {string} The formatted date.
   */
  Date.prototype.fDate = function () {
    return `${this.getDate()}/${this.getMonth() + 1}/${this.getFullYear()}`;
  };

  /**
   * Returns the time in the format HH:MM.
   * @returns {string} The formatted time.
   */
  Date.prototype.fTime = function () {
    const hours = this.getHours().toString().padStart(2, '0');
    const minutes = this.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  /**
   * Returns the date and time in the format DD/MM/YYYY HH:MM.
   * @returns {string} The formatted date and time.
   */
  Date.prototype.fDateTime = function () {
    return `${this.fDate()} ${this.fTime()}`;
  };

  /**
   * Returns the time and date in the format HH:MM DD/MM/YYYY.
   * @returns {string} The formatted time and date.
   */
  Date.prototype.fTimeDate = function () {
    return `${this.fTime()} ${this.fDate()}`;
  };

  /**
   * Adds a specified number of days to the date.
   * @param {number} days - The number of days to add.
   * @returns {Date} The new date.
   */
  Date.prototype.addDays = function (days) {
    const date = new Date(this);
    date.setDate(date.getDate() + days);
    return date;
  };

  /**
   * Adds a specified number of months to the date.
   * @param {number} months - The number of months to add.
   * @returns {Date} The new date.
   */
  Date.prototype.addMonths = function (months) {
    const date = new Date(this);
    date.setMonth(date.getMonth() + months);
    return date;
  };

  /**
   * Checks if the date is in an array of custom tDates.
   * @param {Array} tDates - The array of custom tDates.
   * @returns {boolean} True if the date is in the array, false otherwise.
   */
  Date.prototype.inArrayOfTDates = function (tDates) {
    const date = new Date(this);
    if (tDates.includes(date.tDate())) return true;
    return false;
  };

  /**
   * Checks if the date is in an array of Date objects.
   * @param {Array} dates - The array of Date objects.
   * @returns {boolean} True if the date is in the array, false otherwise.
   */
  Date.prototype.inArrayOfDates = function (dates) {
    const date = new Date(this.getFullYear(), this.getMonth(), this.getDate());
    for (let d of dates) {
      const dt = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      if (d.getTime() === date.getTime()) return true;
    }
    return false;
  };

  /**
   * Returns "am" or "pm" based on the hour of the date.
   * @returns {string} "am" if the time is before noon, "pm" otherwise.
   */
  Date.prototype.ampm = function () {
    const hours = this.getHours();
    if (hours < 12) return "am";
    return "pm";
  };

  /**
   * Returns the difference in minutes between the date and another date.
   * @param {Date} date - The date to compare to.
   * @returns {number} The difference in minutes.
   */
  Date.prototype.differenceInMinutes = function (date) {
    const diff = date - this;
    return Math.floor(diff / 60000);
  };

  /**
   * Returns the difference in hours between the date and another date.
   * @param {Date} date - The date to compare to.
   * @returns {number} The difference in hours.
   */
  Date.prototype.differenceInHours = function (date) {
    const diff = date - this;
    return diff / 3600000;
  };
}

module.exports = addDateFunctions;
