import React, { useState } from "react";
import useDBO from "../../hooks/useDBO";
import Loading from "../../Loading";
import Error from "../../components/Error";


export default function AiFeedback(props) {
    const [selected,setSelected] = useState("")
    const data = useDBO("/ai-testing")
    if (data.loading) return <Loading />
    if(data.error) return <Error />
    console.log(data.get)
    const {summary,feedback} = data.get

    return (
      <div>
        <table>
                <tr>
                    <th>Teacher</th>
                    <th>Average</th>
                    <th>Lowest</th>
                    <th>Highest</th>
                </tr>
          {summary
            .sort((a, b) =>
              a.average > b.average ? 1 : b.average > a.average ? -1 : 0
            )
            .map((s) => (
              <tr>
                    <td style={{cursor:"pointer"}} onClick={()=>setSelected(s.teacher)}>{s.teacher}</td>
                <td>{s.average ? s.average.toFixed(1) : "error"}</td>
                <td>{Math.min(...s.scores)}</td>
                <td>{Math.max(...s.scores)}</td>
              </tr>
            ))}
            </table>
            {selected && <Details teacher={selected} feedback={feedback.filter(f=>f.teacher===selected).sort((a,b) => (a.htClass > b.htClass) ? 1 : ((b.htClass > a.htClass) ? -1 : 0))}/>}
      </div>
    );
}

function Details({ teacher,feedback }) {

    let classes = [...new Set(feedback.map((f) => f.htClass))];
    console.log(classes)
    return (
        <div>
            <hr />
        <h3>{teacher}</h3>
        <div>
          {classes.map((c) => (
            <div>
              <div>{c}</div>
                  <table>
                      <tr>
                          <th>Score</th>
                          <th>Feedback</th>
                          <th>AI's review</th>
                      </tr>
                {feedback
                  .filter((f) => f.htClass === c).sort((a,b) => (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0))
                  .map((f) => (
                      <tr>
                          <td>{f.score}</td>
                          <td>{f.notes}</td>
                          <td>{f.aiNotes}</td>
                    </tr>
                  ))}
              </table>
            </div>
          ))}
        </div>
      </div>
    );
}