import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, List, ListItemButton, TextField, Stack } from "@mui/material";

import React, { useContext } from "react";
import { net } from "../../../../../database";
import { add } from "date-fns";
import Location from "../../../components/Location";
import Lang from "../../../components/Lang";
import { CenterContext, ClassesContext } from "../../../../../Context";
import LocationChoiceChip from "../../../components/LocationChoiceChip";
import { useState } from "react";
import DatePicker from "../../../components/DatePicker";
//import Loading from "../../../Loading";

export default function NewClassDialog(props) {
  const {student,setStudent,setOpen,title} = props
/*   const [classes, setClasses] = React.useState(undefined); */
  const [filter, setFilter] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [reasonErr, setReasonErr] = React.useState(false);
  const [firstClassDate, setFirstClassDate] = React.useState("");
  const [htClassId, setHtClassId] = React.useState(undefined);
  const {center} = useContext(CenterContext)
  const [location,setLocation] = useState(center.locations[localStorage.getItem("location")] || "")
  let {classes, classDays} = useContext(ClassesContext)

  const addClass = () => {
    net
      .post(`/student/${student._id}/classes/add`, {
        _id: htClassId,
        startDate: firstClassDate,
      })
      .then((classes) => {
        setStudent(prev=>({...prev,classes}))
        setReasonErr(false);
        setReason("");
        setOpen(false)
      });
  };

  const selectClass = (htClass) => {
    setHtClassId(htClass._id);
    let dt = new Date();
    let days = htClass.classDays.map((cd) => cd.day);
    let count = 0;

    while (!days.includes(dt.getDay()) && count < 7) {
      dt = add(dt, { days: 1 });
      count++;
    }
    setFirstClassDate(dt.tDate());

  };

 /*  if (classes === undefined) return <div></div>; */

  return (
    <Dialog
      open={props.open}
      fullWidth
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{ mt: 1 }}
        >
       {/*    <TextField
            label={"Date the student will attend their first class"}
            value={firstClassDate}
            onChange={(e) => setFirstClassDate(e.target.value)}
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
          /> */}
          {htClassId && <DatePicker value={new Date(firstClassDate)} setValue={(newDate) => setFirstClassDate(newDate.tDate())} daysToEnableNoPast={classDays(htClassId)} />}
          <TextField
            label={<Lang w="search" />}
            onChange={(e) => setFilter(e.target.value.toLocaleLowerCase())}
            fullWidth
          />

          <LocationChoiceChip
            value={location}
            setValue={setLocation}
          />
        </Stack>
        <List>
          {classes
            .filter(
              (c) =>
                ["Active", "Coming","Tutoring","Club"].includes(c.status) &&
                c.location === location &&
                c.name.toLowerCase().includes(filter)
            )
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map((c) => (
              <ListItemButton
                key={c._id}
                onClick={(e) => selectClass(c)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: c.status === "Active" ? "green" : "orange",
                }}
                selected={c._id === htClassId}
                className="MenuItem"
              >
                <div>{c.name}</div>
                <div>
                  <Location id={c.location} />
                </div>
              </ListItemButton>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={()=>setOpen(false)}
          color="error"
        >
          Close
        </Button>
        <Button
          onClick={addClass}
          color="success"
          disabled={checkIfFalsey([htClassId,firstClassDate])}
        >
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function checkIfFalsey(arrayIn) {
  let r = false
  for (let i of arrayIn) {
    if(!i) return true
  }
}