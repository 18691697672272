import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  LinearProgress,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import AttendanceButtons2 from "./AttendanceButtons2";
import Loading from "../../../Loading";
import { useContext } from "react";
import { CenterContext, StudentsContext } from "../../../../../Context";
import StaffChooserContext from "../../../components/StaffChooserContext";
import HtClass from "../../../components/HtClass";
import MultiLine from "../../../components/MultiLine";
import useDB from "../../../hooks/useDB";
import Error from "../../../components/Error";
import mut from "../../../hooks/mut";


export default function AttendanceDialog({ open, setOpen, lesson, update }) {

  const { center } = useContext(CenterContext);
  const { locations } = center;
  const url = `/lesson/${lesson._id}`;

  const updateL = async (updates) => {
    let updatedLesson = await mut("post", `/lesson/${lesson._id}`, updates)
    update(updatedLesson)
  }

  let progress = calculateProgress(lesson);

  return (
    <div>
      <Dialog
        open={open}
        className="dialog-box"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            paddingBottom: 2,
            paddingRight: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <HtClass
              htClass={lesson.htClass}
              link
            />
          </div>
          <div>
            {lesson.students.length} student
            {lesson.students.length > 1 && "s"}
          </div>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{ pt: 1 }}
            style={{
              marginTop: 0,
              marginBottom: 10,
              display: "flex",
            }}
          >
            <StaffChooserContext
              value={lesson.teacher}
              setValue={(teacher) => updateL({teacher})}
              filter="Teacher"
              fullWidth
              size="small"
            />
            <div style={{ width: 20 }}></div>
            <SelectRoom
              htClass={lesson}
              locations={locations}
              changeRoom={(room) => updateL({room})}
            />
          </Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            {...(progress === 100 && { color: "success" })}
            sx={{ marginBottom: 2, marginTop: 0 }}
          />
          <MultiLine
            value={lesson.adminNotes}
            setValue={(adminNotes) =>
              update({ _id: lesson._id, adminNotes })
            }
            fontSize={12}
            rows={2}
            url={url}
            field="adminNotes"
            label="Notes"
          />
          {lesson.test && (
            <div style={{ padding: 10, fontSize:12, display: "flex", justifyContent: "space-between" }}>
              <div>{lesson.test.room}</div>
              <div>
                <a href={lesson.test.testLink} target="_blank">Test Link</a>
              </div>
            </div>
          )}
          <DrawStudents
            lesson={lesson}
            update={update}   
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={setOpen}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const calculateProgress = (htClass) => {
/*   if (htClass && htClass.lesson) {
    return (
      ((htClass.lesson.attended.length +
        htClass.lesson.absent.length +
        htClass.lesson.noShow.length) /
        htClass.students.length) *
      100
    );
  } */
  return 0;
};



const SelectRoom = (props) => {
  let { htClass, locations, changeRoom } = props;
  return (
    <TextField
      label="Room"
      size="small"
      value={htClass.room ||  ""}
      select
      fullWidth
      onChange={(e) => changeRoom(e.target.value)}
    >
      {locations
        .find((l) => l._id === htClass.location)
        .rooms.map((r) => (
          <MenuItem value={r} key={`roomid${r}`}>
            {r}
          </MenuItem>
        ))}
    </TextField>
  );
};

const DrawStudents = ({ lesson, update }) => {

  return (
    <div>
      {lesson.students.length === 0 ? (
        <div>No Students</div>
      ) : (
        lesson.students
          /*    .filter((s) => {
            let student = findStudent(s);
            if (!student) return false;
            if (student.doesntStudy.includes(htClass.classDay)) return false;
            return true;
          }) */
          .map((s) => (
            <AttendanceButtons2
              student={s}
              lesson={lesson}
              update={update}
              key={s._id}
            />
          ))
      )}
    </div>
  );
};
