import React from "react";
import useDBO from "../../../hooks/useDBO";
import Loading from "../../../Loading";
import HtClass from "../../../components/HtClass";
import Text from "../../../components/Text";



export default function Tests(props) {

    const tests = useDBO(`/tests-by-teacher/`)
    if (tests.loading) return <Loading />
    console.log(tests)
    return (
      <div>
            <h3>Tests ({tests.get.length})</h3>
        {tests.get.map((t) => (
          <div>
            <HtClass
              key={t.htClass}
              htClass={t.htClass}
            /> - {new Date(t.tDate).fDate()}
            <div style={{ display: "flex", flexDirection: "column", marginTop:10, gap:10 }}>
              <Text
                value={t.testLink}
                setValue={(testLink) => tests.update({ ...t, testLink })}
                label="Link to test"
                url={`/test/${t._id}`}
                field="testLink"
              />
              <Text
                value={t.powerpointLink}
                setValue={(powerpointLink) =>
                  tests.update({ ...t, powerpointLink })
                }
                label="Link to powerpoint"
                url={`/test/${t._id}`}
                field="powerpointLink"
              />
            </div>
          </div>
        ))}
      </div>
    );
}