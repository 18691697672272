import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React, { useState } from "react";
import RadioGroupHT from "../../../components/RadioGroupHT";
import Lang from "../../../components/Lang";
import mut from "../../../hooks/mut";
import replaceById from "../../../hooks/replaceById";

export default function RemoveFTDialog({open,setOpen,htClass,week,classDay,setClasses,day}) {

  const [future, setFuture] = useState("allFutureClasses")
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Lang w="removeTheForeignTeacherFromClass" /> {htClass.name}
      </DialogTitle>
      <DialogContent>
        <RadioGroupHT
          choices={["allFutureClasses", "thisClass"]}
          value={future}
          setValue={setFuture}
          label={"changeFor"}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>Close</Button>
        <Button
          color="success"
        onClick={async() => {
                    let res = await mut("post", `/lesson-remove-ft/${htClass._id}/${future}`, {
                      htClass: htClass.htClass,
                      dayOfWeek: day,
                      tDate: htClass.tDate,
                    });
          console.log(res)
          setClasses(prev => prev.map(p=> p._id===res._id ? res : p))
          setOpen(false)
                  }}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}