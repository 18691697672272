import React, { useContext, useState } from "react";
import AddButton from "../../components/AddButton";
import DialogHT from "../../components/DialogHT";
import { UserContext } from "../../../../Context";
import useDBO from "../../hooks/useDBO";
import Loading from "../../Loading";
import { Link } from "react-router-dom";
  
  
  export default function Syllabuses(props) {
  
    const [newSyllabus, setNewSyllabus] = useState(false)
    const { user } = useContext(UserContext)
    const syllabus = useDBO("/syllabus")
  
    if(syllabus.loading) return <Loading />

    return (<div>
    
      <AddButton action={()=>setNewSyllabus(true)} />
      <DialogHT
        open={newSyllabus}
        setOpen={setNewSyllabus}
        url="/syllabus"
        title="addNewSyllabus"
        http="put"
        content={content}
        extraData={{ createdBy: user._id }}
        success={(res)=>syllabus.add(res)}
      />
     
      {syllabus.get.length > 0 && syllabus.get.map((syllabus) => <div>
        <Link to={`/teaching/syllabus/${syllabus._id}`}>{syllabus.name}</Link>
  
      </div>)}

  </div>)
};
  
const content = [{
  name: "name",
  label: "name",
  type: "text"
}]