import { ClickAwayListener } from "@mui/base";
import React from "react";
import Icon from "../../../Icon";
import Lang from "../../../components/Lang";


export default function ContextMenu({handleClickAway, handleCustomContextAction, contextMenu, htClass}) {
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className="custom-context-menu"
        style={{
          left: contextMenu.x,
          top: contextMenu.y,
        }}
      >
        <div className="context-title">Update {htClass.name}</div>
        {/* <ContextItem
          onClick={() => handleCustomContextAction("editClass")}
          icon="edit"
          text="editThisClass"
        /> */}
        {!htClass.foreignTeacher && (
          <ContextItem
            onClick={() => handleCustomContextAction("addForeignTeacher")}
            icon="add"
            text="addAForeignTeacher"
          />
        )}
        {htClass.foreignTeacher && (
          <div>
            <ContextItem
              onClick={() => handleCustomContextAction("addForeignTeacher")}
              icon="edit"
              text="changeTheForeignTeacher"
            />
            <ContextItem
              onClick={() => handleCustomContextAction("removeForeignTeacher")}
              icon="delete"
              text="removeTheForeignTeacher"
              color="red"
            />
          </div>
        )}
        {htClass.test && (
          <ContextItem
            onClick={() => handleCustomContextAction("editTest")}
            icon="edit"
            text="editTheTest"
          />
        )}
        {!htClass.test ? (
          <ContextItem
            onClick={() => handleCustomContextAction("addTest")}
            icon="add"
            text="addATest"
          />
        ) : (
          <ContextItem
            onClick={() => handleCustomContextAction("removeTest")}
            icon="delete"
            text="removeTheTest"
          />
        )}
        <ContextItem
          onClick={() => handleCustomContextAction("moveNextBook")}
          icon="edit"
          text="moveToTheNextBook"
          color="red"
        />
      </div>
    </ClickAwayListener>
  );
}

function ContextItem({ onClick, icon, text, color }) {
  return (
    <div
      className="context-button"
      onClick={onClick}
      style={{ color: color || "black" }}
    >
      <div>
        <Icon name={icon} />
      </div>
      <div>
        <Lang w={text} />
      </div>
    </div>
  );
}