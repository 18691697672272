import React, { useState } from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import VND from "../../components/VND";
import Month from "../../components/Month";
import Text from "../../components/Text";
import { previousDay } from "date-fns";


export default function Financial(props) {


const [data, setData, error, loading] = useDB(`/financial-data`)


if (loading) return <Loading />;
if (error) return <Error />;
    const { books } = data
    const tb = [{ label: "Revenue", value: books.revenue }, {label: "Costs", value: books.costs }, {label: "Profit", value: books.revenue - books.costs }  ];

console.log(data); 


    return (
      <div style={{ width: 300, minWidth: 300 }}>
        Books <Month /> {new Date().getFullYear()}
        <table>
          {tb.map((t) => (
            <tr>
              <td>{t.label}</td>
              <td>
                <VND value={t.value} />
              </td>
              <td></td>
            </tr>
          ))}
        </table>
        <br></br>
        <Text
          label="Salary projection"
          value={data.salaryProjection}
          setValue={(newSalary) =>
            setData((d) => ({ ...d, salaryProjection: newSalary }))
          }
          url="/financial-update-salary-projection"
          field="salaryProjection"
          vnd
        />
        <br></br>
        <br></br>
        <Text
          label="Photocopy bill"
          value={data.photocopyBill}
          setValue={(photocopyBill) =>
            setData((d) => ({ ...d, photocopyBill }))
          }
          url="/financial-update-photocopy-bill"
          field="photocopyBill"
          vnd
        />
      </div>
    );
}