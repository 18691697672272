import React, { useState } from "react";
import useDB from "../../../hooks/useDB";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./days.css"
import { useNavigate } from "react-router-dom";
import Staff from "../../../components/Staff";
import Time from "../../../components/Time";
import HtClass from "../../../components/HtClass";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NewTestDialog from "./NewTestDialog";
import Weekday from "../../student/components/Weekday";
import LetterIcon from "../../../components/LetterIcon";



export default function Days(props) {
  const [midDate,setMidDate] = useState(new Date().startOfWeek())


const [lessons, setLessons, error, loading] = useDB(`/user/days/${midDate}`)


  
  const changeDate = (numDays) => {
  let dt = new Date(midDate)
  dt.setDate(dt.getDate() + numDays)
  setMidDate(dt.tDate())
  }
  
const days = get7Days(midDate)
  

if (loading) return <Loading />;
  if (error) return <Error />;
  console.log(lessons)

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          minHeight: 238,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <BButton
            icon={<KeyboardArrowLeftIcon onClick={() => changeDate(-7)} />}
          />
         {/*  <BButton
            icon={
              <KeyboardDoubleArrowLeftIcon onClick={() => changeDate(-7)} />
            }
          /> */}
        </div>
        {days.map((day) => (
          <DrawDay
            day={day}
            key={day}
           /*  setDays={setDays} */
            loading={loading}
            lessons={lessons.filter(l=>l.tDate===day).sort((a,b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0))}
          />
        ))}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <BButton
            icon={<KeyboardArrowRightIcon onClick={() => changeDate(7)} />}
          />
         {/*  <BButton
            icon={
              <KeyboardDoubleArrowRightIcon onClick={() => changeDate(7)} />
            }
          /> */}
        </div>
      </div>
    </div>
  );
}

function DrawDay(props){


  const { day, setDays, loading, lessons } = props
  const dt = new Date(day)
if(loading) return <Loading />

    return (
      <div className="day-box">
        <div
          className="day-title"
          style={{
            color: day.today ? "green" : null,
            fontWeight: day.today ? "bold" : "normal"
          }}
        >
          <Weekday id={dt.getDay()} /> {dt.fDate()}
        </div>

        <div>
          {lessons.map((l) => (
            <DrawClass
              lesson={l}
              day={day}
              setDays={setDays}
              key={l._id}
            />
          ))}
        </div>
      </div>
    );
}   

function DrawClass(props) {
 
  const { lesson, day } = props
 
    const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const dt = new Date()
 /*  if (htClass.type === "timetable" && new Date(day.tDate) < new Date(dt.getFullYear(),dt.getMonth(),dt.getDate(),7)) return <></>
    if (htClass.type === "timetable") {
        return (
          <div className="day-class-timetable">
            <div>
              <div className="classday-title">
                <HtClass
                  htClass={lesson.htClass}
                  link
                />
                , <Time time={htClass.startTime} />
              </div>
              <div>
                {htClass.fTeachers.map((t) => (
                  <div style={{ color: "gray" }}>
                    <Staff id={t.teacher} />, <Time time={t.startTime} />
                  </div>
                ))}
                {htClass.test ? (
                  <div onClick={() => setOpen(true)} className="test-scheduled">Test Scheduled</div>
                ) : null}
              </div>
            </div> */
           {/*  {!day.today && !htClass.test ? (
              <div>
                <AddButton
                  size={25}
                  tooltip="Add test"
                  action={() => setOpen(true)}
                />
              </div>
            ) : null}
            <NewTestDialog
              open={open}
              setOpen={setOpen}
              {...props}
            /> */}
         /*  </div>
        );
    } */
    const info = [{
        title: "Homework", field:"homework"
        },/*  {
        title: "Feedback", field:"feedback"
        }, {
        title: "Voice feedback", field:"voiceFeedback"
    } */]
   const cl = lesson.cLesson || undefined;
    return (
      <div
        className="day-class"
        onClick={() => navigate(`/feedback2/${lesson._id}`)}
      >
        <div style={{display:"flex", justifyContent: "space-between"}}>
          <div className="classday-title">{lesson.name}</div>
          <DrawInfo />
        </div>
        {cl && (
          <div>
            <div>{cl.syllabus.name}</div>
            <div>
              <div style={{ color: "#888" }}>Unit: {cl.unit.unitNumber}</div>
              <div>{cl.unit.name}</div>
            </div>
            <div>
              <div style={{ color: "#888" }}>Lesson {cl.lessonNumber}</div>
              <div>{cl.name}</div>
            </div>
          </div>
        )}
        {/* {info.map((i) => (
          <DrawInfo
            info={i}
            lesson={lesson}
            key={`${i}${lesson.tDate}`}
          />
        ))} */}
      </div>
    );
}

function DrawInfo({ info, lesson }) {
 
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/*  <div className="classday-field">{info.title}</div>
       
        <div>
                {lesson[info.field] ? <CheckCircleIcon style={{ fontSize: 12, color: "green" }} /> : <CancelIcon style={{ fontSize: 12, color: "red" }} />}
        </div> */}
       {/*  <div style={{display:"flex",gap:3}}>
          <LetterIcon letter="H" />
          <LetterIcon letter="F" />
          <LetterIcon letter="V" />
          <LetterIcon letter="V" />
        </div> */}
      </div>
    );
}

function BButton(props) {
  const {icon, action} = props
  return (
    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
        <IconButton onClick={action}>{icon}</IconButton>
    </div>
  );
}

function get7Days(startDate) {
  const days = [startDate];
  for (let i = 1; i < 7; i++){
    let dt = new Date(startDate)
    dt = dt.addDays(i)
    days.push(dt.tDate())
  }
  return days;
}
