
import useDBO from "../../hooks/useDBO";
import Loading from "../../Loading";
import { useState } from "react";
import Search from "../../components/Search";
  
  export default function Notifications(props) {
    const [filter,setFilter] = useState("")
    const classes = useDBO("/classes-syllabus")
  
    if (classes.loading) return <Loading />
    const list = []
    for (const c of classes.get) {
      let li = list.find(l => l.link === c.syllabusLink)
      if(li) {
        li.classes.push(c.name)
      } else {
        if(c.syllabusLink) list.push({classes:[c.name], link: c.syllabusLink})
      }
    }
   

    return (
      <div>
        <div style={{display:"flex", gap:20, alignItems:"center"}}>
          <h3>Syllabuses ({list.length})</h3>
          <Search
            search={filter}
            setSearch={setFilter}
            size="small"
          />
        </div>
        {list
          .filter((l) =>
            filter === ""
              ? true
              : l.classes.toString().includes(filter.toUpperCase())
          ).sort((a,b) => (a.classes.toString() > b.classes.toString()) ? 1 : ((b.classes.toString() > a.classes.toString()) ? -1 : 0))
          .map((l) => (
            <div>
              <div>{l.classes.toString()}</div>
              <div>
                <a
                  href={l.link}
                  target="_blank"
                >
                  {l.link}
                </a>
              </div>
            </div>
          ))}
        <h3>Classes with no syllabus</h3>
        {classes.get
          .filter((c) => !c.syllabusLink)
          .map((c) => (
            <div>{c.name}</div>
          ))}
      </div>
    );
    
  }