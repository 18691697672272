import React, { useContext } from "react";
import { CenterContext } from "../../../Context";
import SelectFromList from "./SelectFromList";


export default function CenterChoices({value,setValue,centerField, label, url, field, style={}}) {
    const { center } = useContext(CenterContext)
    if (!center[centerField]) return <div>No field called {field}</div>

    return (<SelectFromList choices={center[centerField]} value={value} setValue={setValue} label={label} url={url} field={field} style={style} fullWidth/>)
}