import { MenuItem, TextField } from "@mui/material";
import React, { useContext } from "react";
import { StaffContext } from "../../../Context";
import Lang from "./Lang";

/**
 * Component for selecting staff members from a dropdown list.
 * @component
 * @param {Object} props - Component properties
 * @param {string} props.value - Current selected staff ID
 * @param {Function} props.setValue - Function to handle staff selection changes
 * @param {string} [props.filter] - Optional role filter for staff members
 * @param {boolean} [props.none=false] - Whether to include a "None" option
 * @param {boolean} [props.all=false] - Whether to show all staff or only active ones
 * @param {string} [props.label=""] - Label text for the select field
 * @returns {JSX.Element} A TextField component with staff selection options
 */
export default function StaffChoice(props) {
  const { value, setValue, save, filter = undefined, none, all=false, label="", fullWidth=false,style={} } = props;
  const { staff } = useContext(StaffContext);
  let st = []
  if (all) { st = staff.map(s => ({ id: s._id, label: s.name })) } else {
    st = staff.filter(s=>s.status===0)
  }
  if(filter) st = st.filter(s=> s.roles.includes(filter));

  st = st.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

  return (
    <TextField
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      label={<Lang w={label} />}
      select
      fullWidth={fullWidth}
      style={style}
    >
      {none ? <MenuItem value={""}><Lang w="none" /></MenuItem> : null}
      {st.map((staff) => (
        <MenuItem value={staff._id} key={staff._id}>
          {staff.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
