import React from "react";
import DateOfBirth from "../../../components/DateOfBirth";
import Info from "../../../components/Info";
import CustomerLink from "../../../components/CustomerLink";
import PhoneNumber from "../../../components/PhoneNumber";
import { IconButton,Avatar,Grid,Button } from "@mui/material";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import PendingReturnDate from "./PendingReturnDate";
import ChangeStatusDialog from "./ChangeStatusDialog";
import EditNameDialog from "./EditNameDialog";
import Lang from "../../../components/Lang";
import ConfirmSendToLead from "./ConfirmSendToLead";
import StoppedReason from "./StoppedReason";
import Scholarship from "./Scholarship";
import YesNo from "../../../components/YesNo";

export default function BasicInfo(props) {
    const { student,setStudent } = props;
    const [changeStatusOpen, setChangeStatusOpen] = React.useState(false);
    const [editNameDialog, setEditNameDialog] = React.useState(false);
    const [pendingDate, setPendingDate] = React.useState(student.status!=="Pending" ? new Date() :  student.statusHistory[student.statusHistory.length - 1].returnDate ? new Date(
                student.statusHistory[
                  student.statusHistory.length - 1
                ].returnDate
              )
            : ""
    );
   const [returnToLead,setReturnToLead] = React.useState(false)
      
 
let statusColors = {
   Active: "success",
   New: "success",
   Pending: "warning",
   Stopped: "error",
   Lead: "error",
   HotLead: "info",
 };

return (
  <div>
    <div
      style={{
        float: "right",
        textAlign: "right",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <IconButton onClick={() => setEditNameDialog(true)}>
          <EditIcon />
        </IconButton>
      </div>
     {/*  <Button
        color="error"
        onClick={() => setReturnToLead(true)}
      >
        Return to potential
      </Button>
      {returnToLead ? (
        <ConfirmSendToLead
          open={returnToLead}
          setOpen={setReturnToLead}
          student={student}
          setStudent={setStudent}
        />
      ) : null} */}
    </div>
    <div
      style={{
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {student.image ? (
          <Avatar
            sx={{ width: 80, height: 80, boxShadow: "3px 3px 5px #555555" }}
            src={`/images/${student.image}`}
            alt={student.name}
          />
        ) : (
          <Avatar
            sx={{ width: 80, height: 80, boxShadow: "3px 3px 5px #555555" }}
            alt={student.name}
          />
        )}
        <div style={{ marginLeft: 20, width: "100%" }}>
          <h1 style={{ marginBottom: 0, marginTop: 0 }}>{student.name}</h1>
          <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
            <Info
              title="Primary phone"
              body={
                <a
                  href={"https://zalo.me/" + student.phone.replace("0", "84")}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                  className="neat"
                >
                  <PhoneNumber value={student.phone} />
                </a>
              }
            />
            <div style={{ width: 30 }}></div>
            {student.phone2 ? (
              <Info
                title="Zalo phone"
                body={
                  <a
                    href={
                      "https://zalo.me/" + student.phone2.replace("0", "84")
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                    className="neat"
                  >
                    <PhoneNumber value={student.phone2} />
                  </a>
                }
              />
            ) : null}
            <div style={{ width: 30 }}></div>
            <Info
              title="Parent"
              body={<CustomerLink customer={student.customer} />}
            />
            <div style={{ width: 30 }}></div>
            {student.facebook ? (
              <Info
                title="Facebook"
                body={student.facebook}
              />
            ) : null}
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ width: 150 }}>
              <Info
                title="Status"
                body={
                  <Button
                    onClick={() => setChangeStatusOpen(true)}
                    color={statusColors[student.status]}
                  >
                    {student.status}
                  </Button>
                }
              />
            </div>
            {student.status === "Pending" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Info
                    title="Reason"
                    body={
                      student.statusHistory.length > 0
                        ? student.statusHistory[
                            student.statusHistory.length - 1
                          ].reason_en
                        : ""
                    }
                  />
                </div>
                <div>
                  {student.statusHistory.length < 1 ? (
                    ""
                  ) : (
                    <div>
                      <Info
                        title="Return Date"
                        body={
                          <PendingReturnDate
                            value={pendingDate}
                            setValue={setPendingDate}
                            studentId={student._id}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {student.status === "Stopped" || student.status === "Lead" ? (
              <Info
                title="Reason"
                body={<StoppedReason history={student.statusHistory} />}
              />
            ) : (
              ""
            )}
          </div>
          <Grid
            container
            spacing={2}
            sx={{ padding: 2 }}
          >
            <Grid
              xs={2}
              item
            >
              <div className="small-text">
                <Lang w="startDate" />
              </div>
              <div>{format(new Date(student.startDate), "dd/MM/yyyy")}</div>
            </Grid>
            <Grid
              xs={2}
              item
            >
              <DateOfBirth dateOfBirth={student.dateOfBirth} />
            </Grid>
            <Grid
              xs={2}
              item
            >
              <Info
                title={<Lang w="attendedLessons" />}
                body={student.attendedClasses ? student.attendedClasses : 0}
              />
            </Grid>
            <Grid
              xs={2}
              item
            >
              <Scholarship
                student={student}
                url={`/students/${student._id}`}
                field="scholarship"
                value={student.scholarship}
                setValue={(scholarship) =>
                  setStudent((prev) => ({ ...prev, scholarship }))
                }
                scholarshipDate={new Date(student.scholarshipDate)}
                setScholarshipDate={(scholarshipDate) =>
                  setStudent((prev) => ({ ...prev, scholarshipDate }))
                }
                id={student._id}
              />
            </Grid>
            <Grid xs={2} item>
              <YesNo
                style={{ marginTop: 15, width:100 }}
                label="Pickup service"
                value={student.pickupService || false}
                setValue={(pickupService) =>
                  setStudent((prev) => ({ ...prev, pickupService }))
                }
                url={`/student/${student._id}`}
                field="pickupService"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
    <ChangeStatusDialog
      open={changeStatusOpen}
      setOpen={setChangeStatusOpen}
      student={student}
      setStudent={setStudent}
    />
    <EditNameDialog
      student={student}
      setStudent={setStudent}
      open={editNameDialog}
      setOpen={setEditNameDialog}
      l={props.l}
      language={props.language}
    />
  </div>
);
}