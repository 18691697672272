import React, { useContext, useState } from "react";
import "./attendance.css";
import ListClasses from "./components/ListClasses";
import Loading from "../../Loading";
import AttendanceDialog from "./components/AttendanceDialog";
import { CenterContext } from "../../../../Context";
import HtTabs from "../../components/HtTabs";
import Lang from "../../components/Lang";
import { Button, IconButton } from "@mui/material";
import useDBO from "../../hooks/useDBO";
import Error from "../../components/Error";
import IconButtonHT from "../../components/IconButtonHT";
import AddClassDialog from "./components/AddClassDialog";

export default function Attendance() {
  const { center } = useContext(CenterContext)
  const [selected, setSelected] = useState(undefined);
  const [addClass,setAddClass] = useState(false)
  const todaysClasses = useDBO(`/classes-on-day/${new Date().tDate()}`, 10)
 

  if (todaysClasses.loading) return <Loading />;
  if (todaysClasses.error) return <Error />;


  const labels = center.locations
    .filter((l) => todaysClasses.filter((c) => c.location === l._id).length >0)
    .map(
      (l) =>
        l.name +
        ` (${todaysClasses.filter((c) => c.location === l._id).length})`
  );
  
  const components = center.locations.filter(l=>l.name!=="All locations").map(l=>  <ListClasses
          classes={todaysClasses.filter((c) => c.location === l._id)}    
          setSelected={setSelected}
            />)

  const closeDialog = () => {
    setSelected(undefined)
    todaysClasses.refresh()
  }
  
  return (
    <div>
      <div
        className="dflex-sb"
        style={{ alignItems: "center" }}
      >
        <h1>
          <Lang w="attendance" />
        </h1>
      </div>
      <HtTabs
        labels={labels}
        components={components}
        localStorage="location"
        nextToTabs={<div>
          <PrintButton todaysClasses={todaysClasses} />
          <IconButtonHT icon="add" action={()=>setAddClass(true)} />  
        </div>}
      />
      {selected && (
        <AttendanceDialog
          open={selected ? true : false}
          setOpen={closeDialog}
          lesson={todaysClasses.find(c => c._id === selected)}
          update={(newLesson)=>todaysClasses.update(newLesson)}
        />
      )}
      <AddClassDialog open={addClass} setOpen={setAddClass} todaysClasses={todaysClasses} />
    </div>
  );
}

function PrintButton({todaysClasses}) {
  return <Button variant="contained" size="small" sx={{height: 40}} onClick={()=>printClasses(todaysClasses)}><Lang w="print" /></Button>
}

function printClasses(classes) {
  var printWindow = window.open('', 'PRINT');
  let d = document.getElementById("printDiv")
  printWindow.document.write(`<html><head><title>HT Explore Timetable</title><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"></head><body>`)
  printWindow.document.write(d.innerHTML)
  printWindow.document.write(`</body></html>`)
 
}
