import React, { useContext, useState } from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import Select from "../../components/Select";
import Search from "../../components/Search";
import { CenterContext, StaffContext } from "../../../../Context";
import SelectFromList from "../../components/SelectFromList";
import HtTabs from "../../components/HtTabs";
import Classes from "./components/Classes";
import AddButton from "../../components/AddButton";
import NewClassDialog2 from "./NewClassDialog2";
import WeekChooser from "../../components/WeekChooser";
import { startOfWeek } from "date-fns";
import useDBO from "../../hooks/useDBO";

export default function TimeTable2(props) {
  let { locations,center } = useContext(CenterContext);
  const {staffAll} = useContext(StaffContext)
  const [search, setSearch] = useState("");
  const [teacherFilter, setTeacherFilter] = useState("");
  const [status, setStatus] = useState("Active");
  const [open, setOpen] = useState(false);
  const [week, setWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [location, setLocation] = useState(
    localStorage.getItem("location")
      ? locations[localStorage.getItem("location")]._id
      : locations[0]._id
  );
  const classes = useDBO(`/class-timetable/${week.tDate()}`)

/* 
  React.useEffect(() => {
    if (status !== "Active" ||) {
      net.get(`/htclasses/${status}`).then((response) => {
        setClasses((prev) => response);
      });
     
    }
    if (status === "No foreign teacher") {
      net.get("/classes-with-no-foreign-teacher").then((response) => {
        setClasses((prev) => response);
      });
    }
  }, [status]);
 */
  /* if (classes.loading) return <Loading />; */

  const updateLocation = (newLocation) => {
    setLocation(locations[newLocation]._id)
  }

  const teachers = getTeachers(
    classes.get,
    staffAll,
    location,
    status
  );
 
  let labels = locations.map(l => l.name)
 
  let components = [...locations.map(l => <Classes location={l} classes={classes.get} teacherFilter={teacherFilter} search={search} status={status} setClasses={classes.set} week={week}/>)]

  return (
    <div>

        <HtTabs
        labels={labels}
        loading={classes.loading}
        components={components}
        localStorage="location"
        setLocation={updateLocation}
        nextToTabs={
            <div
              style={{ alignItems: "center", display: "flex", paddingTop: 3 }}
            >
              <WeekChooser value={week} setValue={setWeek} /* style={{marginBottom:12}} */ />
              <div style={{ fontSize: 11, marginRight: 10, width: 100 }}>
                <div className="beginner-legend">Beginner</div>
                <div className="intermediate-legend">Intermediate</div>
                <div className="advanced-legend">Advanced</div>
              </div>
              <Select
                items={[...center.classTypes, "Free time","No foreign teacher"]}
                value={status}
                setValue={setStatus}
                size="small"
                sx={{ mr: 2, width: 200 }}
                label="Status"
              />
              <SelectFromList
                value={teacherFilter}
                setValue={setTeacherFilter}
                choices={teachers}
                label="Teachers"
                style={{ width: 200 }}
                size="small"
                filter="Teacher"
                none
              />
              
              <Search
                search={search}
                setSearch={setSearch}
                size="small"
                sx={{ ml: 2 }}
              />
              <AddButton
                action={() => setOpen(true)}
                tooltip="New Class"
              />
              <NewClassDialog2
                open={open}
                setOpen={setOpen}
              />
            </div>
          }
        />

    </div>
  );
}



function getTeachers(classes, staff, location, status) {
  if(!classes) return []
  let teachers = []

  for (const htClass of classes.filter(c => c.location === location)) {
  
      if (htClass.teacher && htClass.status===status && !teachers.find(t=>t._id && t._id===htClass.teacher)) teachers.push(staff.find(s=>s._id && s._id===htClass.teacher))
      
    if (
        htClass.foreignTeacher &&
        htClass.status === status &&
        !teachers.find((t) => t._id === htClass.foreignTeacher)
      )
        teachers.push(staff.find((s) => s._id === htClass.foreignTeacher));
    }

  return teachers.sort((a,b) => a.name.localeCompare(b.name))
}