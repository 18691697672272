import React, { useContext, useEffect, useState } from "react";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { StudentsContext, UserContext } from "../../../../Context";
import { useNavigate } from "react-router-dom";
import VND from "../../components/VND";
import Loca from "../../components/Loca";
import Info from "../../components/Info";
import Staff from "../../components/Staff";
import Chart from "../../components/Chart";
import ClassTimes from "./ClassTimes";
import { CopyAll } from "@mui/icons-material";
import UpdateLink from "../../components/UpdateLink";
import SelectFromListObject from "../../components/SelectFromListObject";
import EditButton from "../../components/EditButton";
import EditBooksDialog from "./EditBooksDialog";

import UpdateUnitAndLesson from "../../components/UpdateUnitAndLesson";


export default function BasicInfo({htClass,setHtClass,classObject}) {
  const { user } = useContext(UserContext)
  const [chartId,setChartId] = useState(undefined)
  const {findStudent} = useContext(StudentsContext)
  const navigate = useNavigate()
  const [openEditBooksDialog, setOpenEditBooksDialog] = useState(false)
  
  useEffect(() => {
    setChartId(htClass._id)
  }, [])

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>{htClass.name}</h3>
          <div>
            {user.roles.includes("Manager") ||
            user.roles.includes("Head Teacher") ? (
              <Button
                onClick={() => navigate(`/manage/classes/edit/${htClass._id}`)}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
            <Tooltip title="Copy students names">
              <IconButton
                onClick={() => {
                  const text = htClass.students
                    .map((s) => findStudent(s))
                    .reduce((a, b) => a + b.name + "\n", "");
                  navigator.clipboard.writeText(text);
                }}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Info
              title="Teacher"
              body={
                htClass.teacher ? <Staff id={htClass.teacher} /> : "No teacher"
              }
            />
            <Info
              title="Location"
              body={<Loca id={htClass.location} />}
            />
            <Info
              title="Manager"
              body={
                htClass.manager ? <Staff id={htClass.manager} /> : "No manager"
              }
            />
            <ClassTimes htClass={htClass} />
            <div style={{ display: "flex", gap: 20 }}>
              <Info
                title="Students"
                body={htClass.students.length}
              />
              {htClass.pendingStudents.length > 0 ? (
                <Info
                  title="Pending"
                  body={htClass.pendingStudents.length}
                />
              ) : null}
            </div>
          </div>
         {/*  <Chart url={`/class-attendance-data/${chartId}`} /> */}
          <div>
            <Info
              title="Fee"
              body={
                <VND
                  value={htClass.fee.getLast().fee}
                  suffix="đ per lesson"
                />
              }
            />

            <div>
              {/*  <Info
                title="Books"
                body={htClass.books.map((book) => (
                  <div>
                    <a
                      className="neat"
                      href={book.link}
                      target="blank"
                    >
                      {book.name} - {book.sellPrice && <VND value={book.sellPrice} />}
                    </a>
                    <EditButton action={() => setOpenEditBooksDialog(true)} />
                    <EditBooksDialog open={openEditBooksDialog} setOpen={setOpenEditBooksDialog} classBooks={htClass.books} htClass={htClass} setHtClass={setHtClass} />
                  </div>
                ))}
              /> */}
              <UpdateLink
                href={htClass.syllabusLink}
                name={htClass.syllabusName}
                url={`/classes/${htClass._id}/update`}
                field="syllabusLink"
                nameField="syllabusName"
                newData={(newData) =>
                  setHtClass((prev) => ({ ...prev, ...newData }))
                }
                label="Syllabus"
              />
              {/* <div>
                <div>{htClass.syllabusName}</div>
                <div><EditButton action={() => setOpenSyllabus(true)} /></div>
              </div> */}
              <SelectFromListObject
                label="Level"
                choices={["Beginner", "Intermediate", "Advanced"]}
                value={htClass}
                setValue={setHtClass}
                url={`/htclasses/${htClass._id}`}
                field="level"
                size="small"
                style={{ width: 200, marginTop: 15 }}
              />
              <UpdateUnitAndLesson
                htClass={htClass}
                setHtClass={setHtClass}
              />
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
}