import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import StaffChooserContext from "./StaffChooserContext";
import SelectFromList from "./SelectFromList";
import { ClassesContext } from "../../../Context";
import LocationChoice from "./LocationChoice";
import VND from "./VND";
import mut from "../hooks/mut";
import IconButtonHT from "./IconButtonHT";
import CenterChoices from "./CenterChoices";
import Lang from "./Lang";
import AutoCompleteStaff from "./AutoCompleteStaff";

/**
 * A dialog with a title and a series of fields (content) and a set of buttons
 * to confirm or cancel the action.
 * 
 * create objects for each field in content
 * 
 * label(uses Lang component), name, value, notRequired, component -> (value,setValue) => function
 * type (text, date, select, staff, location, vnd, centerChoices)
 * 
 * control specific
 * staff: role
 * select: choices
 * 
 * @param {{open: boolean, setOpen: (open: boolean) => void, title: string, content: {name: string, label: string, state?: any, setState?: (value: any) => void}[], success: (data: any) => void, url?: string, extraData?: any}} props
 * @returns 
 */
export default function DialogHT(props) {
  const { open, setOpen, title, content, success, url, extraData, http = "post" } = props;
  const initialState = () => {

   let obj = {};
   content.filter(c=> c.type!=="calculated").forEach((c) => {
     obj[c.name] = c.state || "";
   });
   return obj;
 };
  const calculatedInitialState = () => {
    let obj = {};
    content
      .filter((c) => c.type === "calculated")
      .forEach((c) => {
        obj[c.name] = c.state || "";
      });
    return obj;
  };
  const [state, setState] = useState(initialState());
  const [disabled, setDisabled] = useState(true)
  const [calculated, setCalculated] = useState(calculatedInitialState())
 

  useEffect(() => {
     updateCalculated()
     let check = false;
     content.forEach((c) => {
       if (state[c.name] === "" && !c.notRequired) check = true;
     });
   
   
    for (let c in calculated) {
        let fld = content.find(f => f.name === c)
        
      if (fld.validate && !fld.validate(calculated[c])) check = true;
   
       }
    
   
    setDisabled(check);
    
  }, [state]);

  const updateCalculated = () => {
    for (let c of content.filter(c => c.type === "calculated")) {
      setCalculated((prev) => ({ ...prev, [c.name]: c.calculation.operation(state[c.calculation.first], state[c.calculation.second]) })) 
    }
  }
 
  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle style={{display:"flex", justifyContent: "space-between"}}>
        <div><Lang w={title} /></div>
        <IconButtonHT icon="close" action={() => { setOpen(false); setState(initialState()) }} />
      </DialogTitle>{" "}
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          padding: 10,
        }}
      >
        {content.map((c) => (
          <DrawContent
            content={c}
            key={c.label}
            state={c.type==="calculated" ? calculated[c.name] : state[c.name]}
            setState={(value) =>
              setState({ ...state, [c.name]: value })
            }
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            setOpen(false)
            setState(initialState())
            setCalculated(calculatedInitialState())
          }}
        >
          Close
        </Button>
        <Button
          color="success"
          disabled={disabled}
          onClick={async () => {
            if (!url) {
              success(state);
              setOpen(false)
              setState(initialState())
              setCalculated(calculatedInitialState())
              return;
            }

            success(await mut(http, url, { ...state,...calculated, ...extraData }));
         

            setOpen(false);
            setState(initialState());
            setCalculated(calculatedInitialState())
          }}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DrawContent({ content, state, setState}) {
  const {label, type, role, choices, field, all, multiline, rows, component, options } = content;
  const { htClasses } = useContext(ClassesContext)

  if (component) return component({ state, setState })
  
    if (type === "vnd") return (
      <VND
        value={state}
        setValue={setState}
        label={<Lang w={label} />}
        fullWidth
      />
    );
  if (type === "autocompletestaff") return (
    <AutoCompleteStaff options={options} value={state} setValue={(val) => {
   
      setState(val)
    }
    } label={label} />
  )

    if (type === "staff") return (
      <StaffChooserContext
        value={state}
        setValue={setState}
        label={<Lang w={label} />}
        role={role}
        fullWidth
      />
    );

     if (type === "class")
       return (
         <SelectFromList
           choices={htClasses}
           label={<Lang w={label} />}
           value={state}
           setValue={setState}
           fullWidth
         />
       );
    
    if(type==="select") return (
      <SelectFromList
        choices={choices}
        label={<Lang w={label} />}
        value={state}
        setValue={setState}
        fullWidth
      />
    );
    
    if (type === "location") {
      return (
        <LocationChoice
          value={state}
          setValue={setState}
          label={<Lang w={label} />}
          size="normal"
          all={all}
          fullWidth
        />
      );
    }
  
  if(type==="centerChoices") return (
    <CenterChoices
      value={state}
      setValue={setState}
      field={field}
      label={<Lang w={label} />}
      fullWidth
    />
  );

  if (type === "calculation") return (
    <TextField
      label={<Lang w={label} />}
      value={state}
      onChange={(e) => setState(e.target.value)}
      type="number"
      fullWidth 
      readOnly
    />
  );

    return (
      <TextField
        label={<Lang w={label} />}
        value={state}
        onChange={(e) => setState(e.target.value)}
        type={type}
        multiline={multiline}
        rows={rows}
        fullWidth
        {...(type==="date" || type==="time" && {
        InputLabelProps:{
          shrink:true} 
        })}
        />
    );
}