import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { TextField } from "@mui/material";
import { net } from "../../../database";

/**
 * 
 * @param {boolean} noPast only allow future dates
 * @param {string} align chooose alignment  
 * @paramt {boolean} future allow future dates default = true
 * @returns 
 */

export default function ChooseDate(props) {
  const { value, setValue, enable, style = {}, label = "", noPast,align,size="small", url="", field="", future=true } = props;

  let options = { dateFormat: "d/m/Y" };
  if (enable) options.enable = enable;
  if (noPast) options.enable = [(date) => date >= Date.now()];
  if (!future) options.enable = [(date) => date <= Date.now()];

  let sd = ""
  try {
    sd = value.fDate()
  } catch (err) {
    console.log("invalid date")
  }

  let inputProps = {}
  if (align) {
    inputProps.style = {textAlign: align}
  }

  const update = (date) => {
     if (url && field) {
       net.post(url, { [field]: date });
     }
    setValue(date)
  }


  return (

    <Flatpickr
      value={value}
      onChange={([date]) => update(date)}
      options={options}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <TextField
            value={value==="" || sd}
            inputRef={ref}
            size={size}
            style={style}
            label={label}
            inputProps={inputProps}
          />
        );
      }}
      />

  );
}
